import React from 'react'
import { Pie } from '@ant-design/charts'

const PieChart = ({ data }) => {
	const config = {
		data,
		angleField: 'count',
		colorField: 'name',
		radius: 0.8,
		label: {
			type: 'inner',
			offset: '-30%',
			style: {
				textAlign: 'center'
			},
			autoRotate: false,
			content: '{percentage}'
		},
		interactions: [{ type: 'element-active' }]
	}

	return (
		<div className="chart-container">
			<Pie {...config} />
		</div>
	)
}

export default PieChart
