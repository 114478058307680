import { useState, useEffect } from 'react'

import style from './style.module.css'
export default function Cookie() {
	const [privacyToken, setPrivacyToken] = useState(false)

	const saveConfirmation = () => {
		if (typeof window !== 'undefined') {
			localStorage.setItem('privacy_token', 'true')
			setPrivacyToken(true)
		}
	}

	const rejectConfirmation = () => {
		if (typeof window !== 'undefined') {
			localStorage.setItem('privacy_token', 'false')
			setPrivacyToken(true)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') setPrivacyToken(Boolean(localStorage.getItem('privacy_token')))
	}, [privacyToken])

	return (
		!privacyToken && (
			<div className={style['cookie_notification']}>
				<div className={style['cookie_notification_div']}>
					<h5 className={style['cookie_notification_h5']}>Our use of cookies</h5>
					<p>
						We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make
						improvements by measuring how you use the site. These will be set only if you accept.
					</p>

					<div className={style.cookie_btn_div}>
						<button className={style.btn_reject} onClick={rejectConfirmation} style={{ marginRight: '20px' }}>
							I decline
						</button>
						<button className={style.btn_accept} onClick={saveConfirmation}>
							I agree
						</button>
					</div>
				</div>
			</div>
		)
	)
}
