import React from 'react'
import styled from 'styled-components'
import Navbar from '../../components/Navbar'

const OurMission = () => {
	return (
		<Wrapper>
			<Navbar />
			<Header>Our Mission</Header>

			<div>
				<ul>
					<li>
						Millions of girls in all countries are sexually exploited by sugar daddies, teachers, and employers in
						exchange for tuition fees, passing courses, and jobs. We believe{' '}
						<b>no girl or young woman should be sexually exploited</b> for education, training, jobs, or work
						experience.
					</li>
					<li>
						We will <b>innovate solutions</b> to ensure all girls and young women can access safe, affordable funding
						for their tuition, living expenses, or enterprise ideas. And that the practice of sugar daddies, sponsors,
						and sex-for-grades becomes socially unacceptable.
					</li>
					<li>
						Girls First Finance <b>supports young women directly</b> through student loans and personal empowerment
						features, including mentorship, counseling, safeguarding support, budgeting tools, and positive social
						networking support.
					</li>
					<li>
						GFF <b>works with others to scale up</b> these solutions. We work with lenders, banks, impact investors,
						donors, and governments to increase access to affordable loans and empowerment. We work with advocates,
						governments, NGOs, and employers to make the practice of sugar daddies and sex-for-grades socially
						unacceptable.
					</li>
				</ul>
			</div>
		</Wrapper>
	)
}

export default OurMission

const Header = styled.h2`
	text-align: center;
	margin: 30px 0;
	font-size: 30px;
`

const Wrapper = styled.div`
	font-size: 20px;
	padding: 0 40px;

	@media (max-width: 768px) {
		padding: 0 10px;
	}

	li {
		margin: 15px 0;
	}
`

const Logo = styled.a`
	& img {
		width: 150px;
		height: auto;
		margin: 10px;
	}
`
