import React from 'react'
import { Checkbox, Select, Space, Tag } from 'antd'
import '../../pages/surveyResult/styles.css'
import styled from 'styled-components'

const SurveyQuestionDropdownSortBy = ({
	genderList,
	ageList,
	continentList,
	subRegionList,
	selectedGenders,
	setSelectedGenders,
	selectedAge,
	setSelectedAge,
	selectedContinent,
	setSelectedContinent,
	selectedSubRegion,
	setSelectedSubRegion,
	fromMinority,
	setFromMinority,
	fromRuralArea,
	setFromRuralArea,
	haveDisability,
	setHaveDisability,
	dropDownVisible,
	setDropDownVisible
}) => {
	const handleChangeForGender = value => {
		const array = [...selectedGenders, value]
		setSelectedGenders([...new Set(array)])
	}

	const handleRemoveGenderFromSelection = gender => {
		const array = selectedGenders?.filter(item => item !== gender)
		setSelectedGenders(array)
	}

	const handleChangeForAge = value => {
		const array = [...selectedAge, value]
		setSelectedAge([...new Set(array)])
	}

	const handleRemoveAgeFromSelection = gender => {
		const array = selectedAge?.filter(item => item !== gender)
		setSelectedAge(array)
	}

	const handleChangeForSubRegion = value => {
		const array = [...selectedSubRegion, value]
		setSelectedSubRegion([...new Set(array)])
	}

	const handleRemoveSubRegionFromSelection = subRegion => {
		const array = selectedSubRegion?.filter(item => item !== subRegion)
		setSelectedSubRegion(array)
	}

	const handleChangeForContinent = value => {
		setSelectedContinent(value)
		setSelectedSubRegion([])
	}

	const handleSelectClick = event => {
		setDropDownVisible(!dropDownVisible)
		event.stopPropagation()
	}

	const handleNestedDropdownClick = event => {
		event.stopPropagation()
	}

	return (
		<>
			<div>
				<Select
					className="main_choose_question"
					// style={{
					// 	width: 500
					// }}
					placeholder="Sort by"
					open={dropDownVisible}
					onClick={handleSelectClick}
					dropdownRender={menu => (
						<div onClick={handleNestedDropdownClick}>
							<div className="text_all_dropdown" style={{ marginTop: '-5px' }}>
								Gender
							</div>
							<Space className="space_style" style={{ width: '100%' }}>
								<Space wrap style={{ width: '100%' }}>
									<Select
										className="main_choose_question_second"
										defaultValue="Select Gender"
										// style={{
										// 	width: 458
										// }}
										value={selectedGenders?.length ? selectedGenders?.join(', ') : 'Select Gender'}
										onChange={handleChangeForGender}
										options={genderList}
									/>
									<div style={{ display: 'flex' }}>
										{selectedGenders?.length
											? selectedGenders?.map(gender => {
													return (
														<Tag
															className="tag_name_style"
															onClose={() => handleRemoveGenderFromSelection(gender)}
															closable
															color="magenta">
															{gender}
														</Tag>
													)
											  })
											: ''}
									</div>
								</Space>
							</Space>

							<div className="text_all_dropdown">Age</div>

							<Space className="space_style" style={{ width: '100%' }}>
								<Space wrap style={{ width: '100%' }}>
									<Select
										className="main_choose_question_second"
										defaultValue="Select Age"
										// style={{
										// 	width: 458
										// }}
										onChange={handleChangeForAge}
										value={selectedAge?.length ? selectedAge?.join(', ') : 'Select Age'}
										options={ageList}
									/>
									<div style={{ display: 'flex' }}>
										{selectedAge?.length
											? selectedAge?.map(age => {
													return (
														<Tag
															className="tag_name_style"
															onClose={() => handleRemoveAgeFromSelection(age)}
															closable
															color="magenta">
															{age}
														</Tag>
													)
											  })
											: ''}
									</div>
								</Space>
							</Space>

							<div className="text_all_dropdown">Region</div>
							<Space className="space_style" style={{ width: '100%' }}>
								<Space wrap style={{ width: '100%' }}>
									<Select
										className="main_choose_question_second"
										defaultValue="Select Continent"
										value={selectedContinent || 'Select Continent'}
										allowClear
										// style={{
										// 	width: 458
										// }}
										onChange={handleChangeForContinent}
										options={continentList}
									/>
								</Space>
							</Space>

							{selectedContinent && subRegionList[selectedContinent]?.length ? (
								<>
									<div className="text_all_dropdown">Sub Region</div>
									<Space className="space_style" style={{ width: '100%' }}>
										<Space wrap style={{ width: '100%' }}>
											<Select
												className="main_choose_question_second"
												defaultValue="Select sub Region"
												// style={{
												// 	width: 458
												// }}
												value={selectedSubRegion?.length ? selectedSubRegion?.join(', ') : 'Select sub region'}
												onChange={handleChangeForSubRegion}
												options={subRegionList[selectedContinent]}
											/>
											<div style={{ display: 'flex' }}>
												{selectedSubRegion?.length ? (
													selectedSubRegion?.map(subRegion => {
														return (
															<Tag
																className="tag_name_style"
																onClose={() => handleRemoveSubRegionFromSelection(subRegion)}
																closable
																color="magenta">
																{subRegion}
															</Tag>
														)
													})
												) : subRegionList[selectedContinent]?.length > 0 ? (
													<p style={{ color: 'red' }}>Please select at least one sub region</p>
												) : (
													''
												)}
											</div>
										</Space>
									</Space>
								</>
							) : (
								''
							)}
							<div className="checkbox_item">
								<Checkbox
									className="check_style_change"
									checked={haveDisability}
									onChange={e => {
										setHaveDisability(e.target.checked)
									}}>
									<span className="check_text_style">Having a disability</span>
								</Checkbox>
							</div>

							<div className="checkbox_item">
								<Checkbox
									className="check_style_change"
									checked={fromMinority}
									onChange={e => {
										setFromMinority(e.target.checked)
									}}>
									<span className="check_text_style">From a minority /minoritized/ discriminated ethnic group</span>
								</Checkbox>
							</div>

							<div className="checkbox_item">
								<Checkbox
									className="check_style_change"
									checked={fromRuralArea}
									onChange={e => setFromRuralArea(e.target.checked)}>
									<span className="check_text_style">Lives in a rural area</span>
								</Checkbox>
							</div>

							{(!selectedContinent || (selectedContinent && selectedSubRegion?.length > 0)) && (
								<ButtonContainer
									style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
									type="submit"
									onClick={() => {
										setDropDownVisible(false)
									}}>
									Save
								</ButtonContainer>
							)}
						</div>
					)}
				/>
			</div>
		</>
	)
}

export default SurveyQuestionDropdownSortBy

const ButtonContainer = styled.div`
	padding: 10px 50px;
	border-radius: 8px;
	background: #3dad99;
	color: #fff;
	font-weight: 700;
	border: 0;
	cursor: pointer;
	opacity: 0.9;
`
