import React from 'react'
import { SectionHeader } from '../pages/Home'
import CoolStuffScroll from './CoolStuffScroll'

const CoolStuff = () => {
  return (
    <div id='stuff'>
      <SectionHeader width='200px'>Cool Stuff</SectionHeader>
      <CoolStuffScroll />
    </div>
  )
}

export default CoolStuff