import React from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import styled from 'styled-components'
import { faces } from '../utils/data'
import { AWS_PATH } from '../constants/images'

const FourFacesScroll = () => {
	return (
		<FourFacesScrollContainer>
			<ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
				{faces.map(face => (
					<ImageCard
						description={face.description} // NOTE: itemId is required for track items
						title={face.title}
						img={face.img}
					/>
				))}
			</ScrollMenu>
		</FourFacesScrollContainer>
	)
}

const LeftArrow = () => {
	const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

	return <StyledFaArrowLeft disabled={isFirstItemVisible} onClick={() => scrollPrev()}></StyledFaArrowLeft>
}

const RightArrow = () => {
	const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

	return <StyledFaArrowRight disabled={isLastItemVisible} onClick={() => scrollNext()}></StyledFaArrowRight>
}

const ImageCard = ({ title, description, img }) => {
	return (
		<CardContainer tabIndex={0}>
			<div className="card">
				<div className="image-container">
					<img src={`${AWS_PATH}/${img}.png`} height="240px" alt={img + 'png'} />
				</div>
				<h3>{title}</h3>
				<p>{description}</p>
			</div>
		</CardContainer>
	)
}

const CardContainer = styled.div`
	.card {
		width: 220px;
		margin-right: 60px;
		overflow: hidden;

		.image-container {
			img {
				border-radius: 12px;
				margin-bottom: 20px;
			}
		}
		h3 {
			font-family: 'Helvetica Neue';
			font-style: normal;
			font-weight: 700;
			font-size: 21px;
			line-height: 27px;
			text-align: center;
			color: #2f3332;
		}
		p {
			margin-top: 15px;
			font-family: 'Helvetica Neue';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #2f3332;
		}
	}
`

const FourFacesScrollContainer = styled.div`
	margin: 20px auto;
	.react-horizontal-scrolling-menu--arrow-left,
	.react-horizontal-scrolling-menu--arrow-right {
		align-items: center;
	}

	@media (max-width: 768px) {
		margin: 20px;

		.react-horizontal-scrolling-menu--arrow-left,
		.react-horizontal-scrolling-menu--arrow-right {
			display: none;
		}
	}
`

const StyledFaArrowLeft = styled(FaArrowLeft)`
	font-size: 24px;
	background: rgba(100, 200, 150, 0.1);
	padding: 30px;
	text-align: center;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 40px 0;
	margin-right: 30px;
	cursor: pointer;
	opacity: ${props => (props.show ? '0.7' : '0')};

	&:hover {
		opacity: ${props => (props.show ? '1' : '0')};
		transition: 0.3s all;
	}
`

const StyledFaArrowRight = styled(FaArrowRight)`
	font-size: 24px;
	background: rgba(100, 200, 150, 0.1);
	padding: 30px;
	text-align: center;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 40px 0;
	margin-left: 30px;
	cursor: pointer;
	opacity: ${props => (props.show ? '0.7' : '0')};

	&:hover {
		opacity: ${props => (props.show ? '1' : '0')};
		transition: 0.3s all;
	}
`

export default FourFacesScroll
