import React, { useState } from 'react'
import styled from 'styled-components'
import { Icons } from './assets/icon'
import { Layout, Drawer } from 'antd';

const { Header } = Layout;

const HeaderContainer = styled(Header)`
	background: #fff;
	display: flex;
	align-items: center;
	height: auto;
	padding: 10px 50px;

	.icon {
		display: none;
		cursor: pointer;
		margin-left: auto;

		@media (max-width: 1024px) {
			display: block;
		}
	}

	.nav-links {
		margin-left: auto;
		margin-right: 10px;
		align-items: center;
		display: flex;

		@media (max-width: 1024px) {
			display: none;
		}

		a {
			color: #010101;
			font-weight: 500;
			font-size: 16px;
			letter-spacing: -0.02em;
			text-decoration: none;
			margin: 0 10px;

			:hover {
				color: #000;
			}
		}
	}

	.social-links {
		display: flex;
		gap: 24px;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f3f3f3;
			border-radius: 100%;
			width: 40px;
			height: 40px;
		}

		@media (max-width: 1024px) {
			display: none;
		}
	}
`;

const NavLogo = ({ styles }) => (
	<div className="logo-container" style={styles}>
		<a href='/'>
			<img src="/logo-transparent.png" className="logo" height="90px" alt="transparent" />
		</a>
	</div>
);

const NavItems = () => (
	<div className="nav-links" id='nav-links'>
		<a href="/">Home</a>
		<a href="/our-mission">Our Mission</a>
		<a href="/#posse">Our Posse</a>
		<a href="/#wonderWoman">Wonder Woman</a>
		<a href="/#wannaHelp">Wanna Help?</a>
		<a href="/#stuff">Cool Stuff</a>
		<a href="/#faqs">FAQs</a>
		<a href="/research">Research</a>
	</div>
);

const NavIcons = () => (
	<div className="social-links">
		<a href="http://facebook.com/girlsfirstfin">
			<Icons name="facebook" />
		</a>
		<a href="http://twitter.com/girlsfirstfin">
			<Icons name="twitter" />
		</a>
		<a href="http://instagram.com/girlsfirstfinance">
			<Icons name="instagram" />
		</a>
	</div>
);

const Navbar = () => {
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
	};

	const handleOpenDrawer = () => {
		setOpenDrawer(true);
	};

	return (
		<>
			<HeaderContainer>
				<NavLogo />
				<NavItems />
				<NavIcons />

				<div className='icon' onClick={handleOpenDrawer}>
					<i className='fa fa-bars fa-2x'></i>
				</div>

			</HeaderContainer>
			<Drawer
				width={300}
				title={<NavLogo styles={{ textAlign: 'end' }} />}
				onClose={handleCloseDrawer}
				open={openDrawer}>
				<DrawerContainer>
					<NavItems />
					<NavIcons />
				</DrawerContainer>
			</Drawer>
		</>
	)
}

export default Navbar;

const DrawerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;

	.nav-links {
		display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
		gap: 10px;
    margin-bottom: 50px;

		a {
			color: #010101;
			font-weight: 500;
			font-size: 16px;
			letter-spacing: -0.02em;
			text-decoration: none;
			margin: 0 10px;

			:hover {
				color: #000;
			}
		}
	}

	.social-links {
		display: flex;
		gap: 24px;
		justify-content: center;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f3f3f3;
			border-radius: 100%;
			width: 40px;
			height: 40px;
		}
	}
`;
