import React from 'react'
import styled from 'styled-components'
import { SectionHeader } from '../pages/Home'
import FourFacesScroll from './FourFacesScroll'
import { AWS_PATH } from '../constants/images'

const Faces = () => (
	<FacesContainer>
		<SectionHeader width="680px" id="wonderWoman">
			A Real Wonder Woman Has Many Faces
		</SectionHeader>
		<p>
			Let's help you master the four faces every woman needs to succeed.
			<br />
			If you can learn to be these four things well, you can do <strong>anything</strong>. We’ve got your back to get
			you there.
		</p>
		<WonderWomanContainer>
			<img className="wonder-woman" src={`${AWS_PATH}/many-faces.png`} alt="wonder-woman" />
		</WonderWomanContainer>
		<FourFacesScroll />
	</FacesContainer>
)

export default Faces

const FacesContainer = styled.div`
	max-width: 1400px;
	margin: 0 auto;

	p {
		margin: 40px 0 20px;
		font-size: 24px;
	}
`

const WonderWomanContainer = styled.div`
	display: flex;

	div {
	}

	img {
		height: 555px;
		margin: 50px auto;
	}

	@media (max-width: 768px) {
		margin: 0 auto;

		img {
			height: 400px;
			width: 100%;
		}
	}
`
