import React from 'react'
import styled from 'styled-components'
import { SectionHeader } from '../pages/Home'
import EmailCard from './EmailCard'
import { AWS_PATH } from '../constants/images'

const GetInvolvedCards = props => (
	<GetInvolvedCardsContainer>
		<SectionHeader width="240px" id="wannaHelp">
			Wanna Help?
		</SectionHeader>
		<CardContainer>
			<Card>
				<CardImageContainer background="#FFF4CF">
					<img src={`${AWS_PATH}/4-people.png`} alt="people" height="100%" width="100%" />
				</CardImageContainer>
				<h3>Join a Crew</h3>
				<p>
					Family isn't just the one you're born into, it's the one you pick. And true support means holding someone
					accountable to their goals even with hard conversations. Crew members see their captain's budgets and can
					offer financial and emotional support.
				</p>
			</Card>
			<Card>
				<CardImageContainer background="#FFF0EB">
					<img src={`${AWS_PATH}/girls-cycling.png`} alt="cycling" height="100%" width="100%" />
				</CardImageContainer>
				<h3>Become a Mentor</h3>
				<p>
					Most users survey a 10 out of 10 in their need and desire for a mentor to help guide them towards success.
					Wanna change one life at a time? Sign up here to mentor a user. Then access formal training to become a
					certified mentor too.
				</p>
			</Card>
			<Card>
				<CardImageContainer background="#CFFFF3">
					<img src={`${AWS_PATH}/high-five.png`} alt="five" height="100%" width="100%" />
				</CardImageContainer>
				<h3>Become a Wellness Warrior</h3>
				<p>
					It's dangerous out there to be a girl. Most have been exposed to traumatic events or exploitation and are best
					treated with counseling. All girls can use some coaching. If you’re a certified counselor or want to become
					one, sign up here to help a girl get her life back on track.
				</p>
			</Card>
			<Card>
				<CardImageContainer background="#CFE8FF">
					<img src={`${AWS_PATH}/thumbs-up.png`} alt="up" height="100%" width="100%" />
				</CardImageContainer>
				<h3>Post a Gig or Job</h3>
				<p>
					Most girls need extra cash. Got an errand to get done? Post it on the app and give that opportunity to a girl
					who’s willing to hustle to do it. Hiring? Our users are keen to work if given a shot.
				</p>
			</Card>
		</CardContainer>
		<EmailCard setEmail={props.setEmail} sendEmail={props.sendEmail} />
	</GetInvolvedCardsContainer>
)

export default GetInvolvedCards

const GetInvolvedCardsContainer = styled.div`
	margin: 100px;

	@media (max-width: 768px) {
		margin: 10px;
	}
`

const CardImageContainer = styled.div`
	background: ${props => props.background || '#eee'};
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const CardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (min-width: 1220px) {
		margin: auto;
		width: 80%;
	}
	@media (min-width: 1400px) {
		margin: auto;
		width: 60%;
	}
	@media (min-width: 1700px) {
		width: 100%;
	}
`

const Card = styled.div`
	min-height: 300px;
	height: 550px;
	width: fit-content;
	max-width: 280px;
	background: white;
	margin: 30px;
	box-shadow: -9px 12px 8px rgba(244, 244, 244, 0.47);
	border-radius: 16px;
	overflow: hidden;

	h3 {
		margin-top: 20px;
		font-weight: 800;
		font-size: 18px;
		padding: 15px;
	}

	p {
		padding: 0 25px;
		font-family: 'Helvetica Neue';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: #2f3332;
		text-align: left;
	}
`
