import React, { useState } from 'react'
import { Col, Divider, Modal as AntDModal, Row } from 'antd'
import { GreenButton } from '../../pages/Survey'
import styled from 'styled-components'
import JoinCommunityModal from '../JoinCommunityModal'
import PhoneInput from 'react-phone-number-input'
import { AWS_PATH } from '../../constants/images'

const SurveyModel = ({ isModalOpen, onClose, callback }) => {
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [isPhoneNumberView, setIsPhoneNumberView] = useState(false)
	const [showDownload, setShowDownload] = useState(false)

	const onSubmit = e => {
		e.preventDefault()
		if (email && !phoneNumber) {
			setIsPhoneNumberView(true)
			return
		}

		callback({
			Email: {
				value: email,
				id: 33
			},
			'Phone Number': {
				value: phoneNumber,
				id: 34
			}
		})
		setShowDownload(true)
		onClose()
	}

	const onCancel = () => {
		callback({
			Email: {
				value: email,
				id: 33
			},
			'Phone Number': {
				value: phoneNumber,
				id: 34
			}
		})
		onClose()
	}

	return (
		<>
			<Modal
				title=""
				open={isModalOpen}
				closable={false}
				onOk={showDownload ? onSubmit : onClose}
				onCancel={showDownload ? onCancel : onCancel}
				bodyStyle={{
					padding: '36px 0 16px 0'
				}}
				footer={null}>
				<Container onSubmit={onSubmit}>
					<div style={{ padding: '0 0 40px 50px' }}>
						<Row>
							<Col lg={{ span: 18 }} sm={{ span: 12 }}>
								{isPhoneNumberView ? (
									<div>
										<Heading3>Get a text alert when we release Artemis!</Heading3>
										<p>
											Get WhatsApp notifications about the Girls First Finance app.{'\n'}
											Be the first to know when we have released Artemis, a feature that will allow you to
											confidentially record and report sexual exploitation or harassment if it ever happens to you.
										</p>

										<PhoneInput
											placeholder={'Phone Number'}
											name="phoneNumber"
											value={phoneNumber}
											onChange={setPhoneNumber}
										/>
									</div>
								) : (
									<div>
										<Heading3>Follow us for more.</Heading3>
										<p>
											Get updates on the report being prepared to combat the sexual exploitation of girls and young
											women around the world, as well as solutions to help empower them!
										</p>
										<label>Email</label>
										<input value={email} type="email" onChange={e => setEmail(e.target.value)} required />
									</div>
								)}
							</Col>
							<SideImageWrapperCol lg={{ span: 6 }} sm={{ span: 12 }}>
								<img src={`${AWS_PATH}/girl.png`} alt="girl" />
							</SideImageWrapperCol>
						</Row>
					</div>
					<Divider style={{ marginTop: 0 }} />
					<ButtonWrapper>
						<GreenButton type="submit">{isPhoneNumberView ? 'Send' : 'Follow'}</GreenButton>
					</ButtonWrapper>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Container>
			</Modal>
			<JoinCommunityModal isModalOpen={showDownload} onClose={() => setShowDownload(false)} />
		</>
	)
}

export default SurveyModel

const SideImageWrapperCol = styled(Col)`
	& > img {
		position: absolute;
		bottom: -40px;
		right: 0;
		width: 200px;
		height: auto;
	}
`

const Heading3 = styled.h3`
	font-size: 24px;
	text-align: left;
`

const Modal = styled(AntDModal)`
	& > .ant-modal-content {
		border-radius: 30px;
		width: 650px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	label {
		color: #585858;
		font-size: 16px;
	}

	input {
		margin-top: 10px;
		padding: 10px;
		border-radius: 8px;
		border: 0;
		flex: 1;
		max-width: 500px;
		width: 100%;
		background: #f6f7f7;
		color: #084d40;

		&:focus {
			outline: none;
		}
	}
`
const CancelButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #3dad99;
	background: transparent;
	border: none;
	padding: 10px;
	font-size: 17px;
	margin: auto;
	margin-top: 6px;
`
const ButtonWrapper = styled.div`
	margin-top: 16px;
	display: flex;
	& > button {
		margin: auto;
		width: 50%;
		opacity: 1;
		padding-top: 8px;
		padding-bottom: 8px;
	}
`

const Container = styled.form`
	& > p {
		color: #585858;
		font-weight: 400;
		text-align: left;
	}
	& > h3 {
		font-size: 26px;
		line-height: 57px;
		text-align: left;
		margin-bottom: 0;
	}
`
