import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from '../../utils/axios'
import Question from './Question'
import { CountryDropdown } from 'react-country-region-selector'
import { toast } from 'react-toastify'
import SurveyModel from '../../components/SurveyModal'
import { Dropdown, Space, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import Navbar from '../../components/Navbar'
import { AWS_PATH } from '../../constants/images'

const SURVEY_ID = 2

const Survey = () => {
	const [questions, setQuestions] = useState([])
	const [answers, setAnswers] = useState({})
	const [otherAnswers, setOtherAnswers] = useState({})
	const [country, setCountry] = useState('')
	const [LANGUAGE, setLanguage] = useState('en')
	const [isLoading, setIsLoading] = useState(false)
	const [isQuestionLoading, setIsQuestionLoading] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const items = [{ label: 'English', key: 'en' },
	{ label: 'Hindi', key: 'hi' },
	{ label: 'Spanish', key: 'sp' },
	{ label: 'Arabic', key: 'ab' },
	{ label: 'Portuguese', key: 'po' },
	{ label: 'French', key: 'fr' },
	{ label: 'Korean', key: 'ko' },
	{ label: 'Bengali', key: 'be' },
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsQuestionLoading(true)
				const { data } = await axios.get(`/survey/public/surveys/${SURVEY_ID}/questions/${LANGUAGE}`)
				setQuestions(data?.data?.questions?.questions || [])
				setIsQuestionLoading(false)
			} catch (error) {
				setIsQuestionLoading(false)
				console.log(error)
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		const unloadCallback = event => {
			event.returnValue = ''
			return ''
		}

		window.addEventListener('beforeunload', unloadCallback)
		return () => {
			window.removeEventListener('beforeunload', unloadCallback)
		}
	}, [])

	const findOptionString = (questionId, optionId) => {
		const question = questions.find(item => item.id === questionId)
		const option = question?.options.find(item => item.id === optionId)
		return {
			answerText: option?.value || '',
			questionText: question?.label || ''
			// answerList: (question?.options || []).map(item => item.value).join('||')
		}
	}

	const onOpen = () => setIsModalOpen(true)
	const onClose = () => setIsModalOpen(false)

	const onSubmit = async arr => {
		const questionAnswers = []

		Object.keys(answers).forEach(key => {
			if (typeof answers[key] === 'object') {
				answers[key].map(answer =>
					questionAnswers.push({
						questionId: +key,
						answer: +answer,
						...findOptionString(+key, +answer)
					})
				)
			} else {
				questionAnswers.push({
					questionId: +key,
					answer: +answers[key],
					...findOptionString(+key, +answers[key])
				})
			}
		})
		Object.entries(otherAnswers).forEach(([key, data]) => {
			questionAnswers.push({
				questionId: +key,
				answer: +data.answer,
				answerText: data.value || ''
			})
		})
		if (questionAnswers.filter(q => q.questionId !== 3 && q.questionId !== 4).length < questions.length - 2) {
			toast.error('Please answer all questions!')
			return
		}

		if (!country) {
			toast.error('Please select your country!')
			return
		}

		questionAnswers.push({
			questionId: 32,
			answer: 0,
			answerText: country,
			questionText: 'Country'
		})

		Object.entries(arr).forEach(([key, data]) => {
			if (data) {
				questionAnswers.push({
					questionId: data.id,
					answer: 0,
					answerText: data.value,
					questionText: key
				})
			}
		})

		try {
			setIsLoading(true)
			const { data } = await axios.post(`/survey/public/surveys/${SURVEY_ID}/submit`, {
				answers: questionAnswers
			})

			setIsLoading(false)

			if (data.code === 200) {
				toast.success('Thank you for your submission!')

				setAnswers({})
				setOtherAnswers({})
				setCountry('')
			}
		} catch (error) {
			setIsLoading(false)
			setIsModalOpen(false)
			toast.error('Failed to submit! Please try again.')
		}
	}

	const validateSubmitedData = () => {
		const questionAnswers = []

		Object.keys(answers).forEach(key => {
			if (typeof answers[key] === 'object') {
				answers[key].map(answer =>
					questionAnswers.push({
						questionId: +key,
						answer: +answer
					})
				)
			} else {
				questionAnswers.push({
					questionId: +key,
					answer: +answers[key]
				})
			}
		})
		Object.entries(otherAnswers).forEach(([key, data]) => {
			questionAnswers.push({
				questionId: +key,
				answer: +data.answer,
				answerText: data.value || ''
			})
		})

		if (questionAnswers.filter(q => q.questionId !== 3 && q.questionId !== 4).length < questions.length - 2) {
			toast.error('Please answer all questions!')
			return
		}

		if (!country) {
			toast.error('Please select your country!')
			return
		}

		onOpen()
	}

	const setLanguageData = (val) => {
		setLanguage(val.key)
		const fetchData = async () => {
			try {
				setIsQuestionLoading(true)
				const { data } = await axios.get(`/survey/public/surveys/${SURVEY_ID}/questions/${val.key}`)
				setQuestions(data?.data?.questions?.questions || [])
				setIsQuestionLoading(false)
			} catch (error) {
				setIsQuestionLoading(false)
				console.log(error)
			}
		}
		fetchData()
	}

	const menuProps = {
		items,
		onClick: setLanguageData,
	};

	return (
		<>
			<Container style={{ background: '#E9F4F5' }}>
				<Navbar />
				<FlexContainer>
					<h1>Help us get your voice heard!</h1>

					<div className="img-wrapper">
						<img src={`${AWS_PATH}/girlstogethere.png`} className="logo" height="250px" alt="transparent" />
					</div>
				</FlexContainer>
				<TextContainer>
					<p>
						Girls First Finance is working to end the exploitative (yet socially accepted) practice of girls and young
						women being pressured into exchanging sex for education fees, living expenses, grades, or work experience.
						We all know that it happens a lot, but there’s no good evidence about how widespread it is. We want to show
						the world how big this problem is, so that everyone will work with us to end it. There are reliable global
						statistics that girls and young women aged 14-27 are the most likely to be sexually abused, but those
						statistics don’t ask who the perpetrators are, and they don’t ask why the girls and young women were
						vulnerable to pressure in the first place.
					</p>

					<p>
						We have created a 3-minute survey to fill those gaps in that evidence, and give you a platform to speak
						truth to power. In about three months, we will publish the results for you and the world to see in a report
						that we are writing with the UK government. If you sign up for the follow-up email, we will send you a copy.
					</p>

					<p>
						Thank you for sharing your personal experiences with us. We know it’s painful to re-live these experiences,
						and we can provide support and counseling via our app, which you can download after the survey. This survey
						is totally anonymous. No one will know how you have answered this survey, even if you sign up to receive
						future emails.
					</p>
				</TextContainer>

				{/*<LanguagePickerWrapper>*/}
				{/*	<Dropdown menu={menuProps}>*/}
				{/*		<Button>*/}
				{/*			<Space >*/}
				{/*				{LANGUAGE === '' ? 'Select Language' : items.filter(y => y.key === LANGUAGE)[0]["label"]}*/}
				{/*				<DownOutlined />*/}
				{/*			</Space>*/}
				{/*		</Button>*/}
				{/*	</Dropdown>*/}
				{/*	/!* <select value={LANGUAGE} onChange={val => setLanguageData(val)}>*/}

				{/*		<option>Please choose one option</option>*/}
				{/*		{options.map((option, index) => {*/}
				{/*			return <option key={index} value={option.value} >*/}
				{/*				{option.name}*/}
				{/*			</option>*/}
				{/*		})}*/}
				{/*	</select> *!/*/}
				{/*	/!* <CountryDropdown value={LANGUAGE} onChange={val => setCountry(val)} /> *!/*/}
				{/*</LanguagePickerWrapper>*/}

				<QuestionsWrapper>
					{isQuestionLoading ? (
						<div>Loading...</div>
					) : (
						<>
							{questions.map((question, idx) => (
								<Question
									key={question.id}
									question={question}
									questionId={question.id}
									options={question.options}
									type={question.type}
									idx={idx + 1}
									answers={answers}
									setAnswers={setAnswers}
									otherAnswers={otherAnswers}
									setOtherAnswers={setOtherAnswers}
									LANGUAGE={LANGUAGE}
								/>
							))}

							<CountryPickerWrapper>
								<h4>{questions.length + 1}.
									{LANGUAGE === 'en' && 'Where do you live?'}
									{LANGUAGE === 'hi' && 'देश का चयन करें?'}
									{LANGUAGE === 'sp' && 'Dónde vives?'}
									{LANGUAGE === 'ab' && 'في أي بلد تعيش؟'}
									{LANGUAGE === 'po' && 'Em que país você mora?'}
									{LANGUAGE === 'fr' && 'Dans quel pays habites-tu ?'}
									{LANGUAGE === 'ko' && '어느 나라에 거주하고 계신가요?'}
									{LANGUAGE === 'be' && 'আপনি কোন দেশে বাস করেন?'}
								</h4>
								<CountryDropdown value={country} onChange={val => setCountry(val)} />
							</CountryPickerWrapper>
						</>
					)}
				</QuestionsWrapper>
				<SubmitButtonWrapper>
					<GreenButton disabled={isLoading || isQuestionLoading} type="button" onClick={validateSubmitedData}>
						Submit
					</GreenButton>
				</SubmitButtonWrapper>
				<SurveyModel isModalOpen={isModalOpen} onClose={onClose} callback={onSubmit} />
			</Container>
		</>
	)
}

export default Survey

const Container = styled.div`
	background: #e9f4f5;
`
const TextContainer = styled.div`
	padding: 35px 85px;
	@media (max-width: 768px) {
		padding: 26px;
	}
	p {
		color: #000;
		margin-bottom: 26px;
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
	}
`

const FlexContainer = styled.div`
	display: flex;
	padding: 68px 85px;
	background: #2b5d59;
	gap: 88px;
	height: 612px;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 94%, 0% 88%);
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 94%, 0% 88%);

	@media (max-width: 768px) {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 97%, 0% 92%);
		padding: 30px;
		flex-direction: column;
	}

	.img-wrapper {
		img {
			height: auto;
			width: 100%;
		}
	}

	h1 {
		font-weight: 600;
		color: #e2f1f2;
		line-height: 84px;
		font-size: 68px;
		width: 50%;

		@media (max-width: 768px) {
			font-size: 42px;
			line-height: 56px;
			width: 100%;
		}
	}
`

const Header = styled.div`
	width: 100%;
	align-items: center;
	margin: auto;
	text-align: center;
`

const SubmitButtonWrapper = styled.div`
	width: 100%;
	align-items: center;
	margin: auto;
	text-align: center;
	z-index: 2;

	button: {
		cursor: pointer;
	}
`

const Footer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	align-items: center;
	margin: auto;
	text-align: left;

	.logo {
		width: 150px;
		height: auto;
		padding: 1rem;
	}
`

export const GreenButton = styled.button`
	padding-top: 10px;
	padding-bottom: 10px;
	width: 424px;
	border-radius: 8px;
	background: #3dad99;
	color: white;
	border: 0;
	margin: 1.5rem 0 10rem 0;
	font-size: 16px;
	font-weight: 500;

	@media (max-width: 768px) {
		width: 50%;
	}
`

const CountryPickerWrapper = styled.div`
	width: 100%;
	margin-top: 28px;

	background-color: #fff;
	border-radius: 51px;
	padding: 37px 42px 48px 42px;
	color: #000;

	@media (max-width: 768px) {
		border-radius: 26px;
		padding: 26px 22px;
	}

	& > h4 {
		font-weight: 500;
		font-size: 15px;
		margin-bottom: 14px;
	}

	& > select {
		height: 46px;
		width: 100%;

		border-radius: 8px;
		background: #f6f7f7;
		border-style: none;
		font-weight: 400;
		font-size: 15px;
		padding-left: 8px;

		:focus-visible {
			outline: none;
		}
	}
`

const LanguagePickerWrapper = styled.div`
	padding: 0px 85px 26px 85px;
	@media (max-width: 768px) {
		padding: 0px 20px 26px 20px;
	}
`

const QuestionsWrapper = styled.div`
padding: 0px 85px 26px 85px;
@media(max - width: 768px) {
	padding: 0px 20px 26px 20px;
}
`

const Row = styled.div`
display: flex;
flex - direction: row;
width: 100 %;
padding: 2rem 1rem;
padding - bottom: 0;

color: #fff;
font - weight: 600;

@media(max - width: 768px) {
	flex - direction: column;
	padding: 1rem;
}
`

const Col = styled.div`
flex: 0.5;
display: flex;
flex - direction: column;
align - items: center;
margin: 1rem;
`

const SideImage = styled.img`
object - fit: cover;
width: 400px;
`

const Logo = styled.div`
padding: 1rem;
	img {
	width: 40 %;
	height: auto;
}

@media(max - width: 768px) {
		img {
		width: 100 %;
	}
}
`
