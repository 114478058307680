import React from 'react';
import { Modal as AntDModal } from 'antd';
import styled from 'styled-components';
import { AWS_PATH } from '../../constants/images';

const JoinCommunityModal = ({ isModalOpen, onClose }) => {
  return (
    <>
      <Modal
        className='temp'
        title=""
        open={isModalOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
      >
        <DownloadButtonWrapper>
          <img src={`${AWS_PATH}/4-people.png`} alt='jumping girls' />
          <h3>Join us! Down the GFF app here.</h3>
          <div className='iconWrapper'>
            <a href='https://apps.apple.com/tt/app/girls-first-finance/id1483077720'>
              <img src={`${AWS_PATH}/appstore.png`} alt='appstore.png' />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.girlsfirstfinance'>
              <img src={`${AWS_PATH}/Play_Store.png`} alt='Play_Store.png' />
            </a>
          </div>
        </DownloadButtonWrapper>

      </Modal>
    </>
  );
};

export default JoinCommunityModal;

const Modal = styled(AntDModal)`
  & > .ant-modal-content {
    border-radius: 16px;
  }
`;

const DownloadButtonWrapper = styled.div`
  & > h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  & > img {
    width: 100%;
    margin-bottom: 20px;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;