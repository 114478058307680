import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';
import { stuff } from '../utils/data';
import { AWS_PATH } from '../constants/images';

const CoolStuffScroll = () => {
  return (
    <StuffScrollContainer>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {stuff.map((item) => (
          <StuffCard
            title={item.title}
            description={item.description}
            img={item.img}
          />
        ))}
      </ScrollMenu>
    </StuffScrollContainer>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <StyledFaArrowLeft show={!isFirstItemVisible} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    </StyledFaArrowLeft>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <StyledFaArrowRight show={!isLastItemVisible} disabled={isLastItemVisible} onClick={() => scrollNext()}>
    </StyledFaArrowRight>
  );
}

function StuffCard({ title, description, img }) {
  return (
    <StuffCardContainer
      tabIndex={0}
    >
      <div className='card'>
        <div className='image-container'>
          <img src={`${AWS_PATH}/${img}.png`} height='200px' alt="stuff" />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </StuffCardContainer>
  );
}

const StuffCardContainer = styled.div`
    .card {
        width: 280px;
        overflow: hidden;
        margin-right: 60px;

        .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                margin-bottom: 20px;
            }
        }
        h3 {
          font-weight: 700;
          font-size: 18px;
        }
        p {
          font-size: 16px;
          text-align: left;
          margin-top: 16px;
        }
        span.title {
          margin: 10px 0;
          font-size: 18px;
        }

        @media (max-width: 768px) {
          width: 90%;
        }
    }
`;

const StuffScrollContainer = styled.div`
    margin: 20px auto;
    max-width: 1250px;

    .react-horizontal-scrolling-menu--scroll-container {
      margin: 0 auto;
    }

    .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
      align-items: center;
    }
    
    @media (max-width: 768px) {
      margin: 20px;
      .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
        display: none;
      } 
    }
`;

const StyledFaArrowLeft = styled(FaArrowLeft)`
    font-size: 24px;
    background: rgba(100,200,150,0.1);
    padding: 30px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 40px 0;
    margin-right: 30px;
    cursor: pointer;
    opacity: ${props => props.show ? '0.7' : '0'};

    &:hover {
      opacity: ${props => props.show ? '1' : '0'};
      transition: 0.3s all;
    }
`;

const StyledFaArrowRight = styled(FaArrowRight)`
    font-size: 24px;
    background: rgba(100,200,150,0.1);
    padding: 30px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 40px 0;
    margin-left: 30px;
    cursor: pointer;
    opacity: ${props => props.show ? '0.7' : '0'};

    &:hover {
      opacity: ${props => props.show ? '1' : '0'};
      transition: 0.3s all;
    }
`;

export default CoolStuffScroll