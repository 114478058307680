import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../../components/Navbar';
import { AiOutlineDownload } from "react-icons/ai";
import { AWS_PATH } from '../../constants/images';

const CONNECTING_DOT_REPORT = {
  thumbnail: `${AWS_PATH}/reports/report-1-thumbnail.png`,
  title: 'Report 1',
  pdf_name: 'Connecting-the-Dots-February-05-2024.pdf',
  extraText: 'Click the image to download our Anthem Award-winning report.'
};


const Reports = () => {
  const navigate = useNavigate();

  const handleSeeSurvey = () => {
    navigate('/survey');
  };

  const handleSeeSurveyResults = () => {
    navigate('/survey-results');
  };

  const handleOnThumbnailClick = (pdf_name) => {
    navigate(`/report/${pdf_name}`);
  };

  return (
    <Container>
      <Navbar />
      <SemiContainer>
        <div className='row'>
          <div className='column'>
            <LeftContainer>
              <div className='anthem-section'>
                <img src={`${AWS_PATH}/anthem-award-banner.png`} alt='anthem-award-banner.png' />
                <p>
                  WE WON <span>TWO</span><br /> ANTHEM AWARDS!
                </p>
              </div>
              <p>
                Sexual exploitation should not be the price of a <span>girl’s education.</span>
              </p>
              <div>
                <p className='small-text'>
                  Help us get your voice heard!
                </p>
                <p onClick={handleSeeSurvey} className='small-text link'>
                  Be part of our report—click here.
                </p>
                <p className='small-text link' onClick={handleSeeSurveyResults}>
                  Click here to access the survey results!
                </p>
              </div>

              <button onClick={() => handleOnThumbnailClick(CONNECTING_DOT_REPORT.pdf_name)}>
                <AiOutlineDownload /> {" "}Download Report
              </button>
            </LeftContainer>
          </div>
          <div className='column'>
            <img
              className='report-thumbnail'
              src={CONNECTING_DOT_REPORT.thumbnail}
              alt={CONNECTING_DOT_REPORT.title}
            />
          </div>
        </div>
      </SemiContainer>

    </Container>
  );
};

export default Reports;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding:  0;
  background: #0d0d2b;
  min-height: 100vh;

  .report-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: -20px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      color: #a0a0a0;
    }
    
    p:hover {
      color: #ffffff;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 70px;
  }

  button {
    padding: 10px 50px;
    width: 220px;
  }
`;

const SemiContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  height: 100%;
  align-items: center;
  display: flex;
  flex: 1;

  .row {
    display: flex;
    gap: 40px;
  }

  .column {
    .report-thumbnail {
      max-height: 70vh;
      max-width: 500px;
      min-width: 300px;
      border-radius: 10px;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;

    .report-thumbnail {
      height: 100%;
      width: 50vw;
    }
  }
  
  @media (max-width: 768px) {
    max-width: 100%;
    .row {
      flex-direction: column;
    }

    .report-thumbnail {
      width: 100%;
      margin-bottom: 40px;
      max-width: 100% !important;
    }
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: space-between;

  .anthem-section {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  img {
    width: 134px;
    height: 95px;
  }

  p {
    font-size: 30px;
    color: #FFF;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  span {
    color: #F3FF72;
  }

  .small-text {
    font-size: 16px;
    color: #FFF;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .anthem-section {
      flex-direction: column;
      gap: 5px;
    }
  }
`;
