export const Icons = props => {
	return (
		<>
			{props?.name === 'facebook' && (
				<svg
					width={props?.width || '12'}
					height={props?.height || '19'}
					viewBox="0 0 12 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10.4678 10.688L11.001 7.24943H7.6669V5.01805C7.6669 4.07732 8.13265 3.16035 9.6259 3.16035H11.1416V0.232793C11.1416 0.232793 9.76615 0.000488281 8.45102 0.000488281C5.70527 0.000488281 3.91052 1.6474 3.91052 4.62877V7.24943H0.858398V10.688H3.91052V19.0005H7.6669V10.688H10.4678Z"
						fill={props?.color || 'black'}
					/>
				</svg>
			)}
			{props?.name === 'twitter' && (
				<svg
					width={props?.width || '20'}
					height={props?.height || '15'}
					viewBox="0 0 20 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M17.5073 3.51788C17.5193 3.67229 17.5193 3.82672 17.5193 3.98113C17.5193 8.69067 13.6218 14.1172 6.49823 14.1172C4.30361 14.1172 2.26491 13.5326 0.549988 12.5179C0.861802 12.551 1.16158 12.562 1.48539 12.562C3.29622 12.562 4.96319 11.9995 6.29436 11.04C4.59143 11.0069 3.16433 9.98114 2.67263 8.56936C2.9125 8.60243 3.15233 8.6245 3.40419 8.6245C3.75196 8.6245 4.09977 8.58036 4.42354 8.50319C2.64867 8.17228 1.31747 6.73848 1.31747 5.00686V4.96276C1.83313 5.22747 2.4328 5.3929 3.06835 5.41494C2.02501 4.77522 1.34147 3.68332 1.34147 2.44802C1.34147 1.78626 1.53331 1.17965 1.86912 0.650231C3.77592 2.81199 6.64212 4.22374 9.85606 4.37817C9.79611 4.11347 9.76012 3.83776 9.76012 3.56202C9.76012 1.59876 11.487 -0.000488281 13.6337 -0.000488281C14.749 -0.000488281 15.7563 0.429658 16.4639 1.12451C17.3393 0.970107 18.1788 0.672298 18.9223 0.26422C18.6345 1.09144 18.0229 1.7863 17.2194 2.22744C17.9989 2.15028 18.7544 1.9517 19.45 1.67599C18.9224 2.38185 18.2628 3.0105 17.5073 3.51788V3.51788Z"
						fill={props?.color || 'black'}
					/>
				</svg>
			)}
			{props?.name === 'instagram' && (
				<svg
					width={props?.width || '16'}
					height={props?.height || '16'}
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.00357 3.95732C5.73214 3.95732 3.89999 5.76084 3.89999 7.99678C3.89999 10.2327 5.73214 12.0362 8.00357 12.0362C10.275 12.0362 12.1071 10.2327 12.1071 7.99678C12.1071 5.76084 10.275 3.95732 8.00357 3.95732ZM8.00357 10.6229C6.53571 10.6229 5.33571 9.44521 5.33571 7.99678C5.33571 6.54834 6.53214 5.3706 8.00357 5.3706C9.47499 5.3706 10.6714 6.54834 10.6714 7.99678C10.6714 9.44521 9.47142 10.6229 8.00357 10.6229V10.6229ZM13.2321 3.79209C13.2321 4.31592 12.8036 4.73428 12.275 4.73428C11.7429 4.73428 11.3179 4.3124 11.3179 3.79209C11.3179 3.27178 11.7464 2.8499 12.275 2.8499C12.8036 2.8499 13.2321 3.27178 13.2321 3.79209ZM15.95 4.74834C15.8893 3.48623 15.5964 2.36826 14.6571 1.44717C13.7214 0.526074 12.5857 0.237793 11.3036 0.174512C9.98214 0.100684 6.02142 0.100684 4.69999 0.174512C3.42142 0.234277 2.28571 0.522558 1.34642 1.44365C0.407136 2.36475 0.11785 3.48271 0.0535645 4.74482C-0.0214355 6.0456 -0.0214355 9.94443 0.0535645 11.2452C0.114279 12.5073 0.407136 13.6253 1.34642 14.5464C2.28571 15.4675 3.41785 15.7558 4.69999 15.819C6.02142 15.8929 9.98214 15.8929 11.3036 15.819C12.5857 15.7593 13.7214 15.471 14.6571 14.5464C15.5929 13.6253 15.8857 12.5073 15.95 11.2452C16.025 9.94443 16.025 6.04912 15.95 4.74834V4.74834ZM14.2429 12.6409C13.9643 13.33 13.425 13.8608 12.7214 14.1386C11.6679 14.5499 9.16785 14.455 8.00357 14.455C6.83928 14.455 4.33571 14.5464 3.28571 14.1386C2.58571 13.8644 2.04642 13.3335 1.76428 12.6409C1.34642 11.6038 1.44285 9.14287 1.44285 7.99678C1.44285 6.85068 1.34999 4.38623 1.76428 3.35264C2.04285 2.66357 2.58214 2.13271 3.28571 1.85498C4.33928 1.44365 6.83928 1.53857 8.00357 1.53857C9.16785 1.53857 11.6714 1.44717 12.7214 1.85498C13.4214 2.1292 13.9607 2.66006 14.2429 3.35264C14.6607 4.38975 14.5643 6.85068 14.5643 7.99678C14.5643 9.14287 14.6607 11.6073 14.2429 12.6409Z"
						fill={props?.color || 'black'}
					/>
				</svg>
			)}
		</>
	)
}
