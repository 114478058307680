import React from "react";
import { DownloadAppButtonWrapper } from "../../components/MobileSection";
import Navbar from "../../components/Navbar";
import { SectionHeader } from "../Home";
import styled from "styled-components";

const Download = () => {
  return (
    <div className="App">
      <Navbar />
      <Container>
        <SectionHeader>
          <h2>Mobile App</h2>
        </SectionHeader>
        <p>
          Join our community to receive daily affirmations, cool content and updates on our progress as additional
          resources are added. In the meantime, enjoy our daily stories, music suggestions, and messages, curated just
          for you. All of this is just for you.
        </p>
        <DownloadAppButtonWrapper className="button-wrapper">
          <a
            href="https://apps.apple.com/tt/app/girls-first-finance/id1483077720"
            target="_blank"
            class="market-btn apple-btn"
            rel="noreferrer"
            role="button">
            <span class="market-button-subtitle">Download on the</span>
            <span class="market-button-title">App Store</span>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.girlsfirstfinance&pli=1"
            target="_blank"
            class="market-btn google-btn"
            rel="noreferrer"
            role="button">
            <span class="market-button-subtitle">Download on the</span>
            <span class="market-button-title">Google Play</span>
          </a>
        </DownloadAppButtonWrapper>
      </Container>
    </div>
  )
}

export default Download;

const Container = styled.div`
	margin: 20px auto;
	padding: 50px 100px;
	max-width: 1250px;
	align-items: flex-start;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .button-wrapper {
    justify-content: center;
  }
`;