import styled from 'styled-components'

const SINGLE_CHOICE = 'SINGLE_CHOICE'
const OTHER = 'other'

const Question = ({
	questionId,
	question,
	options,
	type,
	idx,
	answers,
	setAnswers,
	otherAnswers,
	setOtherAnswers,
	LANGUAGE
}) => {
	const handleChange = (event, question_type, option) => {
		setAnswers(prevState => {
			const answers = { ...prevState }
			if (question_type === SINGLE_CHOICE) {
				answers[event.target.name] = event.target.value
			} else {
				if (String(option).toLowerCase()?.includes(OTHER)) {
					setOtherAnswers(prevState => ({ ...prevState, [event.target.name]: { answer: event.target.value } }))
				}
				if (String(option).toLowerCase()?.includes(OTHER) && answers[event.target.name]?.includes(event.target.value)) {
					delete otherAnswers[event.target.name]
					setOtherAnswers({ ...otherAnswers })
				}
				if (answers[event.target.name]) {
					if (answers[event.target.name]?.includes(event.target.value)) {
						answers[event.target.name] = answers[event.target.name].filter(value => value !== event.target.value)
					} else {
						answers[event.target.name] = [...answers[event.target.name], event.target.value]
					}
				} else {
					answers[event.target.name] = [event.target.value]
				}
			}
			return answers
		})
	}

	const handleTextAreaChange = event => {
		setOtherAnswers(prevState => ({
			...prevState,
			[event.target.name]: { ...otherAnswers[event.target.name], value: event.target.value }
		}))
	}

	return (
		<QuestionsContainer>
			<h4>
				{idx}.&nbsp;&nbsp;{}
				{LANGUAGE === 'en' && question.label}
				{LANGUAGE === 'hi' && question.label_hi}
				{LANGUAGE === 'sp' && question.label_sp}
				{LANGUAGE === 'ab' && question.label_ab}
				{LANGUAGE === 'po' && question.label_po}
				{LANGUAGE === 'fr' && question.label_fr}
				{LANGUAGE === 'ko' && question.label_ko}
				{LANGUAGE === 'be' && question.label_be}
			</h4>
			{options
				?.sort((a, b) => a?.order - b?.order)
				?.map(option => (
					<>
						<Input className="input">
							<input
								type={type === SINGLE_CHOICE ? 'radio' : 'checkbox'}
								id={questionId}
								name={questionId}
								value={option.id}
								onChange={e => {
									handleChange(e, type, option.value)
								}}
								checked={
									type === SINGLE_CHOICE
										? answers[questionId] === `${option.id}`
										: answers[questionId]?.includes(`${option.id}`)
								}
							/>
							<label for={questionId}>
								{LANGUAGE === 'en' && option.value}
								{LANGUAGE === 'hi' && option.value_hi}
								{LANGUAGE === 'sp' && option.value_sp}
								{LANGUAGE === 'ab' && option.value_ab}
								{LANGUAGE === 'po' && option.value_po}
								{LANGUAGE === 'fr' && option.value_fr}
								{LANGUAGE === 'ko' && option.value_ko}
								{LANGUAGE === 'be' && option.value_be}
							</label>
						</Input>
						{String(option.value).toLowerCase()?.includes(OTHER) ? (
							<TextArea
								name={questionId}
								value={otherAnswers[questionId]?.value || ''}
								onChange={handleTextAreaChange}
								rows="2"
								// cols="50"
								disabled={!answers?.[questionId]?.includes(`${option.id}`)}
							/>
						) : null}
					</>
				))}
		</QuestionsContainer>
	)
}

export default Question

// padding: 37px 52px 48px 42px;
export const QuestionsContainer = styled.div`
	align-self: flex-start;
	width: 100%;

	background-color: #fff;
	border-radius: 50px;
	padding: 30px 40px;
	color: #000;
	margin-top: 28px;

	@media (max-width: 768px) {
		padding: 26px 22px;
		border-radius: 26px;
	}

	& > h4 {
		font-weight: 500;
		font-size: 15px;
		margin-bottom: 14px;
	}

	& p {
		margin-bottom: 0.25rem;
	}
`

const Input = styled.div`
	display: flex;
	align-items: center;
	font-weight: 500;
	margin-bottom: 14px;
	margin-left: 20px;
	gap: 12px;

	& > label {
		font-size: 15px;
		font-weight: 500;
	}

	input[type='checkbox'],
	input[type='radio'] {
		box-sizing: border-box;
		padding: 0;
		transform: scale(1.2);
		height: 18px;
		width: 18px;
	}

	&:last-child {
		margin-bottom: 0px;
	}
`

const TextArea = styled.textarea`
	border-radius: 8px;
	margin-top: 8px;
	background: #f6f7f7;
	width: 100%;
	border-style: none;
	margin-left: 20px;
	margin-right: 50px;
	padding: 10px;
	font-size: 15px;
	font-weight: 500;

	:focus-visible {
		outline: none;
	}

	@media (max-width: 768px) {
		width: 89%;
	}
`
