import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';
import { team } from '../utils/data';
import { AWS_PATH } from '../constants/images';

function TeamScroll() {
  return (
    <TeamScrollContainer>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {team.map((person) => (
          <PersonCard
            name={person.name}
            bio={person.bio}
            img={person.img}
            title={person.title}
            label={person.label}
          />
        ))}
      </ScrollMenu>
    </TeamScrollContainer>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <StyledFaArrowLeft disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    </StyledFaArrowLeft>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <StyledFaArrowRight disabled={isLastItemVisible} onClick={() => scrollNext()}>
    </StyledFaArrowRight>
  );
}

function PersonCard({ name, img, title, label, bio }) {
  return (
    <PersonCardContainer
      tabIndex={0}
    >
      <div className='card'>
        <span className='label'>{label}</span>
        <div className='image-container'>
          <img src={`${AWS_PATH}/team/${img}.png`} height='280px' alt={img} />
        </div>
        <h3>{name}</h3>
        <span className='title'>{title}</span>
        <p>{bio}</p>
      </div>
    </PersonCardContainer>
  );
}

const PersonCardContainer = styled.div`
    .card {
        width: 280px;
        overflow: hidden;
        margin-right: 50px;

        .image-container {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                margin-bottom: 20px;
            }
        }
        h3 {
          font-weight: 700;
          font-size: 18px;
        }
        p {
          font-size: 16px;
          text-align: left;
          margin-top: 16px;
        }
        span.title {
          margin: 10px 0;
          font-size: 18px;
        }
        span.label {
          font-size: 20px;
          font-weight: 700;
          color: #3DAD99;
          opacity: 0.7;
        }

        @media (max-width: 768px) {
          min-width: 280px;
          width: 90%;
        }
    }
`;

const TeamScrollContainer = styled.div`
    margin: 20px auto;
    max-width: 1400px;

    .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
      align-items: center;
    }

    @media (max-width: 768px) { 
      .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
        display: none;
      }

      margin: 20px;
    }
`;

const StyledFaArrowLeft = styled(FaArrowLeft)`
    font-size: 24px;
    background: rgba(100,200,150,0.1);
    padding: 30px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 40px 0;
    margin-right: 30px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      transition: 0.3s all;
    }
`;

const StyledFaArrowRight = styled(FaArrowRight)`
    font-size: 24px;
    background: rgba(100,200,150,0.1);
    padding: 30px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 40px 0;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      transition: 0.3s all;
    }
`;

export default TeamScroll