export const stuff = [
	{
		title: 'Budget Buster',
		description: `Need help budgeting? We give you the tools to help you understand how to make your money work for you.`,
		img: 'budget-buster'
	},
	{
		title: 'Loan Ledger',
		description: `School’s expensive. We got you! With our loans, you can stop stressing about how to pay and get down to learning. Apply right from your phone and keep track of loan payments so you never fall behind.`,
		img: 'loan-ledger'
	},
	{
		title: 'Crew Captain',
		description: `Need help being accountable? Want your very own board of directors? Create a crew of four (with at least two adults) to set goals and share financial planning progress. We’ll tell them how you’re doing, good, bad or ugly. And we’ll encourage them to support you with cash or advice where possible.`,
		img: 'crew-captain'
	},
	{
		title: 'Friend Finder',
		description: `Everyone can use a new friend, especially a fellow sister who shares your desire to put girls first. Search for like-minded peeps in your area and around the world to build your social network of support.`,
		img: 'friend-finder'
	},
	{
		title: 'Mentor Matcher',
		description: `Everybody needs a mentor. Everybody. But many girls struggle to make that initial connection. We’re scouring the globe to bring you mentors not just locally but around the world. Fill out a short questionnaire and we can start to pair you with a mentor easy peasy.`,
		img: 'mentor-matcher'
	},
	{
		title: 'Resume Rocker',
		description: `Without a laptop, creating a resume is a pain. Not anymore! Insert your bio information on your phone and pick a template from several options that reflects your personal and professional style. Save it on the app, send it to others and apply for jobs on our Opportunity Oasis.`,
		img: 'resume-rocker'
	},
	{
		title: 'Wellness Warriors',
		description: `We’re way too 21st century to not realize how much more we can thrive when we prioritize our mental health. Let it go, and you’ll likely let critical opportunities go with it. If you’re struggling, we’ll make sure you get the help needed by helping you search for certified counselors and life coaches right on the app.`,
		img: 'wellness-warrior'
	},
	{
		title: 'Gig Gladiator',
		description: `Need a little extra cash but don’t have time for a full-on job? Check out postings from other users looking for help with errands and short-term tasks. Show your hustle and make new professional contacts along the way too.`,
		img: 'gig-gladiator'
	},
	{
		title: 'Artemis',
		description: `The protector of women and children, Artemis, is a safe-space designed to help survivors reclaim their power by storing evidence and details privately. Don’t want to talk about it yet? Securely create a report of your experience without pressure or coercion. You own your story and choose how to share it or not.`,
		img: 'artemis'
	},
	{
		title: 'Helpline',
		description: `If things feel really bad, we’ll ensure you have a line of support to find urgent care or an ear to lift the darkest clouds and ensure you’re safe and secure.`,
		img: 'helpline'
	},


	{
		title: 'Sticky Situation',
		description: `Feeling uncomfortable in a meeting? At school? At work? Drop your phone “by accident” and trigger customized pre-programmed options that get your a way out elegantly without alerting the creep of your concerns.`,
		img: 'sticky-situation'
	},
	{
		title: 'Lifelong Learner',
		description: `Ready to take the next step in your education? University is exciting, but the application process can be frustrating and overcomplicated. We’re building a common application system starting in  Africa so you can quickly and easily apply to several universities with a “one and done” application.`,
		img: 'grad-girl'
	},
	{
		title: 'Meetups',
		description: `Sometimes growth means just showing up. Meetups can be professional networking events, group and study with your buddies. You can create events, sign up for events, and rate events attended.`,
		img: 'meetups'
	},
	{
		title: 'Opportunity Oasis',
		description: `Sort out your resume on our app and start applying for internships and full-time jobs posted by users and companies that also want to put girls first. Seek out help from job counselors too.`,
		img: 'opp-oasis'
	},
]

export const faces = [
	{
		title: 'Providers',
		description: `You need to look after yourself and likely many others. Let's get you on track to build financial independence and wealth. Jisimamia!`,
		img: 'girl3'
	},
	{
		title: 'Hobnobbers',
		description:
			'As important as what you know is who you know. Build your network to build your foundation for success.',
		img: 'girl2'
	},
	{
		title: 'Industrialists',
		description:
			'Every work experience brings you closer to your dreams. But we learn best by doing so get yourself out there to build the career of your life.',
		img: 'girl1'
	},
	{
		title: 'Guardians',
		description: `It's so dangerous out there for young women! Learn how to protect yourself and heal if you're ever harmed.`,
		img: 'girl4'
	}
]

export const team = [
	{
		name: 'Andrea Pizziconi',
		bio: `Serial founder, social entrepreneur, angel investor, recording artist, songwriter, producer, urban developer, education reformer, and Chartered Financial Analyst. Unapologetically uninterested in fitting inside a box. More excited about Girls First Finance than anything she's ever created before.`,
		label: 'The OG',
		title: 'Founder and CEO',
		img: 'pizziconi'
	},
	{
		name: 'Kavita Tanna',
		bio: `Self-reflector, Community Creator, Global Traveller, Education Disruptor, Change Catalyst.
        Transforming lives through safe spaces, meaningful connections and global perspectives.
        Dancing with joy about women empowerment through Girls First Finance.`,
		label: 'Coach',
		title: 'Chief of Staff',
		img: 'kavita'
	},
	{
		name: 'Peris Mburu',
		bio: `Microfinance maven and international development diva. Women’s financial inclusion initiatives are her jam. Two dozen years in the bag as a credit specialist and commercial banker. Equal parts policy wonk and practioner enabling investible business across African markets. Passionate promoter of SMEs, agriculture, youth and women.`,
		label: 'Consigliere',
		title: 'Head of Credit and Advisory Board',
		img: 'peris'
	},
	{
		name: 'Mbatha Mbithi',
		bio: `Deputy Director Partnerships & Resource Mobilization at Kenya Development Corporation. Former chief marketing maven at ICDC and senior investment officer. Also formerly Head of Credit at three different Kenyan banks making her a triple threat advocate for women’s banking and financial inclusion.`,
		label: 'Consigliere',
		title: 'Advisory Board',
		img: 'mbatha'
	},
	{
		name: 'Charles Ringera',
		bio: 'Chief Executive Officer, Higher Education Loans Board. Turned HELB into a distinguished DFI by balancing the student customer experience with a firm hand of sustainability through collections. Former regulator and internal auditor with decades of experience cutting across all facets of government and commercial banking throughout East Africa. Pioneer of credit, compliance and risk management products and enthusiastic promoter of credit bureaus to increase financial inclusion.',
		label: 'Consigliere',
		title: 'Advisory Board',
		img: 'ringera'
	},
	{
		name: 'Vadim Pochernin',
		bio: 'The engineer that cares. Deliverer of systems that matter and users love. Has been seen on motor bikes blowing off steam. Otherwise always cool as a cucumber nomatter what crisis is unfolding.',
		label: 'Chief Geek',
		title: 'CTO',
		img: 'vadim'
	},
	{
		name: 'Kaushal Koladiya',
		bio: 'Web development wizard. Claims no favorites between front- and back-end applications. Has a passion for being passionate and starts each day trying to build better what he finished the day before.',
		label: 'Geek Squad',
		title: 'Full Stack Dev',
		img: 'kaushal',
	},
	{
		name: 'Maryna Kamnieva',
		bio: 'Master of sports in UI/UX design from Ukraine. A lover of everything related to the design world, but not more than couple hours per day. I am not as bad at drawing and even animation as at cooking.',
		label: 'Designer Diva',
		title: 'UI/UX Designer',
		img: 'maryna'
	},
	{
		name: 'Halyna Kryvosheieva',
		bio: `Fierce users' advocate. Pain in the butt for dev teams who try to cut corners on the user experience. Cares deeply that GFF becomes the most useful and enjoyable tool a girl could ever have. The eyes and ears on the team...she's got your back!`,
		label: 'Task Master',
		title: 'Quality Assurance Captain',
		img: 'halyna'
	},
	{
		name: `Karen D'Souza`,
		bio: `Warrior for gender equality and education in India, personally and professionally with a double degree in Communications. Works with undergraduate and graduate students to enable their admissions to Top 100 universities in the US, Canada, UK etc. while continuing to work with GFF to bring purposeful content for GFF's youthful readership.`,
		label: 'Content Queen',
		title: 'Content Queen',
		img: 'karen'
	},
]

export const mobileScreens = [
	'mobile-1',
	'mobile-2',
	'mobile-3',
	'mobile-4',
	'mobile-5',
	'mobile-6',
	'mobile-7',
	'mobile-8'
]
