import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { BASE_URL } from '../utils/axios';

const EmailCard = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email,
      name,
      comment
    };

    fetch(`${BASE_URL}/feedback`, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => res.json()).then((data) => {
      if (data.code) {
        toast.success(`Thank you for your valuable feedback!`)
        setEmail('');
        setName('');
        setComment('');
      } else {
        toast.error(`Something went wrong. Please try again.`)
      }
    }).catch(err => {
      console.log("-------------------error-------------------");
      console.log(err)
    });
  }

  return (
    <EmailCardContainer>
      <h3>Tell us what you think!</h3>
      <form onSubmit={handleSubmit}>
        <div className='input-container'>
          <input onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            type='email' placeholder='Email' />
          <input onChange={(e) => setName(e.target.value)}
            value={name}
            required
            type='text' placeholder='Name' />
        </div>
        <div className='input-container'>
          <textarea required value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Comment' />
        </div>
        <div className='input-container'>
          <button type='submit'>Send</button>
        </div>
      </form>
    </EmailCardContainer>
  )

}
export default EmailCard


const EmailCardContainer = styled.div`
  padding: 50px 100px;
  background: none;
  border-radius: 20px;
  max-width: 900px;
  margin: 30px auto;

  h3 {
    font-weight: 700;
    font-size: 18px;
    color: #3DAD99;
  }

  .input-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      margin-right: 20px;
      width: 50%;
    }
    
    input:last-child {
      margin-right: 0;
    }
    
    textarea {
      max-width: 800px;
    }

    input, textarea {
      font-size: 18px;
      padding: 10px;
      border-radius: 0px;
      border: 0;
      border-bottom: 2px solid #ddd;
      flex: 1;
      background: none;

      &:focus {
        outline: none;
        border-bottom: 2px solid #3DAD99;
        transition: 0.3s all;
      }
    }

    button {
      padding: 10px 50px;
      border-radius: 8px;
      background: #3DAD99;
      color: white;
      border: 0;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        transition: 0.3s all;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 50px 20px;

    .input-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input, textarea {
        width: 100%;
        margin: 0;
      }

      textarea {
        padding: 0
      }
    }
  }
`