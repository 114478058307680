import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AWS_PATH } from '../constants/images'

const VoiceHeardCard = () => {
	const navigate = useNavigate()

	return (
		<VoiceCardContainer onClick={() => navigate('/survey')}>
			<img src={`${AWS_PATH}/voice-card-text.png`} height="107px" alt="Get your voice heard" />
			<div className="voice-img-text-card">
				<img src={`${AWS_PATH}/voice-card-girl.png`} height="140px" alt="Girl-img" />
				<VoiceCardText>
					<div className="card-text">
						<h3>Speak truth to power by filling out this survey!</h3>
					</div>
				</VoiceCardText>
			</div>
		</VoiceCardContainer>
	)
}

export default VoiceHeardCard

// position: absolute;
// 	left: 50px;
// 	bottom: 100px;

const VoiceCardContainer = styled.div`
	border: 9px solid #fff;
	width: 270px;
	height: 270px;
	cursor: pointer;
	background: #f9c858;
	color: #0f172a;
	padding: 30px;
	border-radius: 50%;
	margin-left: 20px;

	.voice-img-text-card {
		transform: rotate(-3.21deg);
	}

	@media (max-width: 768px) {
		width: 150px;
		height: 150px;
		margin-bottom: 90px;

		& > img {
			width: 100%;
			height: auto;
		}

		.voice-img-text-card {
			& > img {
				width: 100%;
				height: auto;
			}
		}
	}
`

// bottom: auto;
// 		right: auto;
// 		left: 20px;

const VoiceCardText = styled.div`
	position: absolute;
	background: #fff;
	border-radius: 16px;
	width: 256px;
	height: 60px;
	top: 122px;
	left: -20px;

	.card-text {
		background: #a41c52;
		border-radius: 16px;
		width: 253px;
		height: 62px;
		transform: rotate(2deg);
		margin-left: 5px;
		margin-top: -4px;
	}

	h3 {
		color: #fff;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 23px;
		text-align: center;
		padding: 5px 10px;
	}

	@media (max-width: 768px) {
		top: 70px;
		left: -50px;
	}
`
