import React from 'react'
import styled from 'styled-components'
import Navbar from '../../components/Navbar'
import { useParams } from 'react-router-dom'
import { AWS_PATH } from '../../constants/images'

const Wrapper = styled.main`
	display: flex;
	flex-direction: column;
	height: 100vh;
`

const IFrame = styled.iframe`
	flex: 1;
	width: 100%;
`

const Report = () => {
	const params = useParams();

	return (
		<Wrapper>
			<Navbar />
			<IFrame
				title="report"
				src={`${AWS_PATH}/survey-reports/${params.report}`}
				type="application/pdf"
			/>
		</Wrapper>
	)
}

export default Report
