import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AWS_PATH } from '../constants/images';

const AnthemAwardBanner = () => {
	const navigate = useNavigate()

  return (
    <Container onClick={() => navigate('/survey')}>
      <img src={`${AWS_PATH}/anthem-award-banner.png`} alt="Anthem Award Banner" />

      <div className='anthem-text'>
        <p>WE WON <span className='yellow'>TWO</span>
          <p>ANTHEM AWARDS!</p>
          <p className='small-text'>
            WINNING SPEECH HERE!
          </p>
        </p>
      </div>
    </Container>
  )
}

export default AnthemAwardBanner

const Container = styled.div`
  margin-left: 20px;
  cursor: pointer;

  .anthem-text {
    margin: auto;
    margin-top: 10px;
    font-size: 30px;
    padding-left: 20px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    color: #ffffff;

    .yellow {
      color: #F3FF72;
    }

    p {
      margin: 0;
      line-height: normal;
    }

    .small-text {
      font-size: 24px;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 50px;
    .anthem-text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;
