import React, { useState } from 'react'
import styled from 'styled-components'
import JoinCommunityModal from './JoinCommunityModal'
import { AWS_PATH } from '../constants/images'

const WelcomeCard = props => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleOnSubmit = e => {
		e.preventDefault()
		setIsModalOpen(true)
		props.sendEmail()
	}

	return (
		<Wrapper>
			<div className="card-content">
				<img src={`${AWS_PATH}/4-people.png`} height="120px" alt="4-people" />
				<h3>Hello Rockstar!</h3>
				<form className="form">
					<input onChange={e => props.setEmail(e.target.value)} type="email" placeholder="Email" />
					<button onClick={handleOnSubmit}>Join our community!</button>
				</form>
			</div>

			<JoinCommunityModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</Wrapper>
	)
}

export default WelcomeCard

// position: absolute;
// bottom: 50px;
// right: 50px;
const Wrapper = styled.div`
	background: #fff;
	border-radius: 16px;
	width: 250px;
	height: 290px;
	margin-right: 60px;

	.card-content {
		padding: 20px;
		background: #c8da2c;
		border-radius: 16px;
		width: 270px;
		height: 300px;
		transform: rotate(1deg);
		margin-left: 10px;
		margin-top: -11px;

		h3 {
			margin: 0 0 5px 0;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 27px;
			text-align: center;
			color: #2f3332;
		}

		.form {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 20px;
			width: 100%;
		}

		input {
			padding: 10px;
			border-radius: 0px;
			border: 0;
			border-bottom: 2px solid #084d40;
			flex: 1;
			max-width: 500px;
			width: 100%;
			background: none;
			color: #084d40;

			&:focus {
				outline: none;
				border-bottom: 2px solid #084d40;
				transition: 0.3s all;
			}
		}

		button {
			padding: 10px 30px;
			border-radius: 4px;
			background: #084d40;
			color: white;
			border: 0;
			cursor: pointer;
			opacity: 0.9;
			max-width: 500px;
			width: 100%;
			font-weight: 500;

			&:hover {
				opacity: 1;
				transition: 0.3s all;
			}
		}
	}

	@media (max-width: 768px) {
		bottom: 10px;
		right: auto;
		left: 10px;
		margin-left: 20px;
		margin-right: 0;
	}
`
