import React, { useState } from 'react'
import styledComponents from 'styled-components';
import { SectionHeader } from '../Home'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../utils/axios';


const ResetPassword = () => {
  const navigate = useNavigate();
  const queryParam = new URLSearchParams(window.location.search);

  const token = queryParam.get('token');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }

    fetch(`${BASE_URL}/auth/change-password`, {
      body: JSON.stringify({ password, token }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.json()).then((data) => {
      if (data.code === 200) {
        toast.success('Password changed successfully!');
        setPassword('');
        setConfirmPassword('');
        navigate('/');
      } else {
        toast.error(data.message);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <div>
      <SectionHeader style={{ width: '100%' }}>Reset Password</SectionHeader>
      <ResetCardContainer>

        <form onSubmit={handleSubmit}>
          <div className='input-container'>
            <input onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              type='password' placeholder='Enter new password' />
            <input onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              required
              type='password' placeholder='Confirm new password' />
          </div>
          <div className='input-container'>
            <button type='submit'>Reset password</button>
          </div>
        </form>
      </ResetCardContainer>
    </div>
  )
}

export default ResetPassword

const ResetCardContainer = styledComponents.div`
  padding: 50px 100px;
  background: none;
  border-radius: 20px;
  max-width: 900px;
  margin: 30px auto;

  h3 {
    font-weight: 700;
    font-size: 18px;
    color: #3DAD99;
  }

  .input-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      margin-right: 20px;
      width: 50%;
    }
    
    input:last-child {
      margin-right: 0;
    }
    
    textarea {
      max-width: 800px;
    }

    input, textarea {
      font-size: 18px;
      padding: 10px;
      border-radius: 0px;
      border: 0;
      border-bottom: 2px solid #ddd;
      flex: 1;
      background: none;

      &:focus {
        outline: none;
        border-bottom: 2px solid #3DAD99;
        transition: 0.3s all;
      }
    }

    button {
      padding: 10px 50px;
      border-radius: 8px;
      background: #3DAD99;
      color: white;
      border: 0;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        transition: 0.3s all;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 50px 20px;

    .input-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input, textarea {
        width: 100%;
        margin: 0;
      }

      textarea {
        padding: 0
      }
    }
  }
`