import React from 'react'
import { SectionHeader } from '../pages/Home'
import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

const Faqs = () => {
	return (
		<div id="faqs">
			<SectionHeader>FAQs</SectionHeader>
			<FaqContainer>
				<Collapse>
					<Panel header="Is GFF only for girls?" key="1">
						<p>
							Girls First Finance is available to young women and men. However, in order to gain full access to our
							services, young men will be asked to attend a personal and professional development program, which, in
							combination with the GFF services, will set them up for academic and financial success.
						</p>
					</Panel>
					<Panel header="How do I apply for and receive a loan?" key="2">
						<p>
							After the launch of the loan application service, you'll be able to easily apply for a GFF loan. To start,
							we will be giving loans to over 4,000 students in the first two years, and this number will grow as GFF
							does.
						</p>
					</Panel>
					<Panel header="Will GFF give me a job or internship?" key="3">
						<p>
							In the words of OutKast, "Nothin' is for certain". Though we cannot make any guarantees, the resources
							provided by Girls First Finance, such as our professional networking and mentorship features, will
							certainly give every member of our community an edge over the competition.
						</p>
					</Panel>
					<Panel header="How does one find a mentor on the Girls First Finance application?" key="4">
						<p>
							To be paired with a mentor, one must first register on the Girls First Finance application. The Mentor
							Matcher feature can be accessed through a premium subscription to the Girls First Finance app or with an
							introductory promo code. With access to the feature, the user will be prompted to take an online
							psychometric evaluation. Based on the test results, the Mentor Matcher feature will display a list of
							possible mentors. It is upto the user to interact, communicate and exchange ideas with the prospective
							mentor and oneself to assess the right fit.
						</p>
					</Panel>
					<Panel header="What do I have to do to gain access to all of these features?" key="5">
						<p>
							Girls First Finance is a subscription-powered app. For a small fee of $3 per month, subscribers receive
							full access to our suite of personal and professional development tools, from mentorship, to wellness, to
							financial planning. Additionally, our professional development curriculum will be facilitated by
							accredited organizations that share our passion for supporting young women everywhere, such as CAMFED and
							the AAYMCA.
						</p>
					</Panel>
					<Panel header="How can I delete my account in GFF?" key="6">
						<p>To delete your GFF account, you can either:</p>
						<p>1. Use the GFF app:</p>
						<p>a. Open the app and go to your account settings</p>
						<p>b. Tap on "Delete Account" and follow the prompts</p>
						<p>2. Contact GFF support:</p>
						<p>a. Email support at info@girlsfirstfinance.com or</p>
						<p>b. Submit a ticket through the GFF website</p>
					</Panel>
					<Panel
						header="What happens if I have active sessions, mentees, or loans when trying to delete my account?"
						key="7">
						<p>
							Before proceeding with account deletion, our system will check if you have any scheduled sessions (as a
							participant or Wellness Warrior), active mentees, or an active loan. If you have any active obligations,
							you will be informed about them and provided with guidance on how to proceed. You may need to complete
							your obligations or wait for them to conclude before your account can be deleted.
						</p>
					</Panel>
					<Panel header="Is there a survey I have to complete to delete my account?" key="8">
						<p>
							Yes, you'll be asked to complete a brief survey about your reasons for leaving and any feedback you have
							about GFF. Your responses help us improve the user experience.
						</p>
					</Panel>
					<Panel header="What happens to my personal information after I delete my account?" key="9">
						<p>
							After your account is deleted, your personal information will be removed from our systems in accordance
							with our privacy policy. However, some data may be retained for legal purposes or to maintain the
							integrity of our services.
						</p>
					</Panel>
					<Panel header="Can I reactivate my account after deleting it?" key="10">
						<p>No, account deletion is permanent. If you wish to use GFF again, you must create a new account.</p>
					</Panel>
					<Panel header="Will my messages and interactions be deleted when I delete my account?" key="11">
						<p>
							Your messages will remain visible to other users but will no longer be associated with your name.
							Interactions, such as friend connections or mentor-mentee relationships, will be removed.
						</p>
					</Panel>
					<Panel header="How long does the account deletion process take?" key="12">
						<p>
							Once you've completed the necessary steps and confirmed your account deletion request, the process
							typically takes up to 48 hours. You will receive a confirmation email once your account has been
							successfully deleted.
						</p>
					</Panel>
					<Panel header="What should I do if I have an unpaid balance and want to delete my account?" key="13">
						<p>
							Before you can delete your GFF account, you must first settle any outstanding balances. Once your balance
							is paid in full, you can proceed with the account deletion process.
						</p>
					</Panel>
				</Collapse>
			</FaqContainer>
		</div>
	)
}

const FaqContainer = styled.div`
	max-width: 1000px;
	color: rgba(100, 200, 150, 1);
	text-align: left;
	margin: 0px auto 0;

	.ant-collapse {
		background: transparent;
		border: none;
		padding: 50px;

		.ant-collapse-header-text {
			color: #3dad99;
			font-weight: 600;
		}

		svg {
			color: #3dad99;
		}
	}

	@media (max-width: 768px) {
		.ant-collapse {
			padding: 20px;
		}
	}
`

export default Faqs
