import React, { useState } from 'react'

import styled from 'styled-components'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'
import MobileSection from '../components/MobileSection'
import WelcomeCard from '../components/WelcomeCard'
import Team from '../components/Team'
import Faces from '../components/Faces'
import GetInvolvedCards from '../components/GetInvolvedCards'
import Navbar from '../components/Navbar'
import CoolStuff from '../components/CoolStuff'
import Faqs from '../components/Faqs'
// import SurveyCard from '../components/SurveyCard'
import VoiceHeardCard from '../components/voiceHeardCard'
import AnthemAward from '../components/AnthemAward'
import AnthemAwardBanner from '../components/AnthemAwardBanner'
import { AWS_PATH } from '../constants/images'

const SERVICE_ID = 'service_5cswscl'
const TEMPLATE_ID = 'template_zl5kyoi'
const EMAILJS_PUBLIC_KEY = 'PEtdyHuy5hF50hBWO'

const Home = () => {
	const [email, setEmail] = useState('')

	var templateParams = {
		email
	}

	const sendEmail = () => {
		if (!email) {
			console.error('No email entered!')
		}
		emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, EMAILJS_PUBLIC_KEY).then(
			response => {
				console.log('SUCCESS!', response.status, response.text)
				toast.success(`Thanks for signing up! We'll email you soon.`)
				setEmail('')
			},
			error => {
				toast.error(`Failed to sign up! Please try again.`)
				console.error('\n\nError!', error)
			}
		)
	}

	return (
		<div className="App">
			<Navbar />
			<Cover
				style={{
					backgroundImage: `url('${AWS_PATH}/3-girls-stock.png')`,
				}}
			>
				<div className="container">
					{/* <VoiceHeardCard /> */}
					<AnthemAwardBanner />
					<WelcomeCard setEmail={setEmail} sendEmail={sendEmail} />
				</div>
			</Cover>
			<Wrapper>
				<Team />
				<Faces />
				<AnthemAward />
				<GetInvolvedCards setEmail={setEmail} sendEmail={sendEmail} />
				<CoolStuff />
				<Faqs />
				<MobileSection />
			</Wrapper>
		</div>
	)
}

export const SectionHeader = styled.h2`
	border-bottom: 3px solid #3dad99;
	width: ${props => props.width || '130px'};
	height: 40px;
	overflow: none;
	text-overflow: nowrap;
	margin: 50px auto 0;
	font-family: 'Helvetica Neue';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 27px;
	text-align: center;
	letter-spacing: 0.06em;
	color: #2f3332;

	@media (max-width: 768px) {
		width: 90%;
		padding-bottom: 20px;
		height: auto;
	}
`

const Cover = styled.div`
	min-height: 90vh;
	background-repeat: none;
	background-size: cover;
	background-position: center center;
	position: relative;
	@media (max-width: 768px) {
		min-height: 95vh;
	}

	.container {
		position: absolute;
		bottom: 0;
		left: auto;
		width: 100%;
		display: flex;
		margin-bottom: 100px;
		justify-content: space-between;
		z-index: 1;
	}

	@media (max-width: 768px) {
		.container {
			position: absolute;
			bottom: 0;
			display: block;
			margin: auto;
			margin-bottom: 0;
		}
	}
`

const Wrapper = styled.div`
	max-width: 1800px;
	margin: 0 auto;
	padding-bottom: 60px;
`

export default Home
