import React from 'react'
import styled from 'styled-components';
import { SectionHeader } from '../pages/Home';
import TeamScroll from './TeamScroll';

const Team = () => (
  <TeamContainer>
    <SectionHeader width='200px' id='posse'>Our Posse</SectionHeader>
    <TeamScroll />
  </TeamContainer>
)

export default Team

const TeamContainer = styled.div`
  background: none;

  .dots {
    background: none;
    margin: 0 auto;

    li {
      color: #000;
      background: #999;
      margin-right: 10px;
    }
    
    li.slick-active {
      background: #3DAD99;
    }
  }
`;