import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ResetPassword from './pages/Auth/ResetPassword'
import Home from './pages/Home'
import Survey from './pages/Survey'

import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/antd.css'
import './App.css'
import SurveyResult from './pages/surveyResult'
import OurMission from './pages/OurMission'
import Research from './pages/Research'
import Download from './pages/Download'
import Report from './pages/Report'
import Cookie from './components/Cookie/Cookie'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/resetPassword',
		element: <ResetPassword />
	},
	{
		path: '/survey',
		element: <Survey />
	},
	{
		path: '/survey-results',
		element: <SurveyResult />
	},
	{
		path: '/our-mission',
		element: <OurMission />
	},
	{ path: '/research', element: <Research /> },
	{ path: '/report/:report', element: <Report /> },
	{
		path: '/download',
		element: <Download />
	}
])

function App() {
	return (
		<div style={{ position: 'relative', overflowX: 'hidden' }}>
			<Cookie />
			<RouterProvider router={router} />
			<ToastContainer />
		</div>
	)
}
export default App
