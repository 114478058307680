import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SurveyQuestionDropdown from '../../components/surveyDropdown'
import { Col, Row, Table, Tag } from 'antd'
import SurveyQuestionDropdownSortBy from '../../components/surveyDropdownSortBy'
import { HomeOutlined } from '@ant-design/icons'

import './styles.css'
import axios from '../../utils/axios'
import PieChart from './PieChart'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { AWS_PATH } from '../../constants/images'

const SurveyResult = () => {
	const navigate = useNavigate()

	const [isQuestionLoading, setIsQuestionLoading] = useState(false)
	const [questionsList, setQuestionsList] = useState([])
	const [selectedQuestion, setSelectedQuestion] = useState()

	const [genderList, setGenderList] = useState([])
	const [ageList, setAgeList] = useState([])
	const [continentList, setContinentList] = useState([])
	const [subRegionList, setSubRegionList] = useState()

	const [selectedGenders, setSelectedGenders] = useState([])
	const [selectedAge, setSelectedAge] = useState([])
	const [selectedContinent, setSelectedContinent] = useState()
	const [selectedSubRegion, setSelectedSubRegion] = useState([])

	const [fromMinority, setFromMinority] = useState(false)
	const [fromRuralArea, setFromRuralArea] = useState(false)
	const [haveDisability, setHaveDisability] = useState(false)

	const [dropDownVisible, setDropDownVisible] = useState(false)
	const [displayQuestion, setDisplayQuestion] = useState(true)
	const [result, setResult] = useState()
	const [message, setMessage] = useState('')

	const scrollRef = useRef()

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		const que = questionsList?.filter(item => item?.value === selectedQuestion)

		if (que?.length) {
			setDisplayQuestion(que[0]?.label)
		}
	}, [selectedQuestion])

	const fetchData = async () => {
		try {
			setIsQuestionLoading(true)
			const { data } = await axios.get(`/analysis-for-pai/pre-survey-data`)
			setQuestionsList(data?.data?.questionsList || [])
			setSelectedQuestion(data?.data?.questionsList[0]?.value)

			setGenderList(data?.data?.genderList)
			setAgeList(data?.data?.ageList)
			setContinentList(data?.data?.continentList)
			setSubRegionList(data?.data?.regionsWithSubregions)
			setMessage(data?.data?.message)

			setIsQuestionLoading(false)
		} catch (error) {
			setIsQuestionLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		handleCollectSurveyData()
	}, [
		selectedContinent,
		selectedSubRegion,
		selectedQuestion,
		selectedGenders,
		selectedAge,
		fromMinority,
		fromRuralArea,
		haveDisability
	])

	const handleCollectSurveyData = async () => {
		setIsQuestionLoading(true)

		let payload = {
			continent: selectedContinent,
			subregion: selectedSubRegion,
			filterQuestion: selectedQuestion,
			gendersFilter: selectedGenders,
			agesFilter: selectedAge,
			fromMinority,
			fromRuralArea,
			haveDisability
		}

		const { data } = await axios.post(`/analysis-for-pai/analysis`, payload)
		// setDropDownVisible(false)
		setMessage(data?.displayMessage)
		setIsQuestionLoading(false)

		let obj = data?.data
		if (Object.keys(data?.data)?.includes('non') || Object.keys(data?.data)?.includes('oui')) {
			obj = {
				...data.data,
				No: data?.data['No'] + data?.data['non'] || 0,
				Yes: data?.data['Yes'] + data?.data['oui'] || 0
			}

			delete obj.non
			delete obj.oui
		}

		delete obj.null

		const totalCount = Object.values(obj).reduce((sum, count) => sum + count, 0)

		let result = Object.entries(obj).map(([name, count]) => ({
			name,
			count,
			percentage: ((count / totalCount) * 100).toFixed(2) + '%'
		}))

		result = result?.map(data => {
			if (data?.name === '' || data?.name === ' ') {
				return { ...data, name: 'Not Answered' }
			} else {
				return data
			}
		})

		setResult(result)
		// window.scrollBy({
		// 	down: 100,
		// 	behavior: 'smooth' // This enables smooth scrolling
		// })
	}

	const columns = [
		{
			title: '',
			dataIndex: 'name',
			key: 'name',
			render: text => <a>{text}</a>
		},
		{
			title: 'Count',
			dataIndex: 'count',
			key: 'count'
		},
		{
			title: 'Percentage',
			dataIndex: 'percentage',
			key: 'percentage'
		}
	]

	useEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
	}, [result])

	const handleRemoveGenderFromSelection = gender => {
		const array = selectedGenders?.filter(item => item !== gender)
		setSelectedGenders(array)
	}

	const handleRemoveAgeFromSelection = gender => {
		const array = selectedAge?.filter(item => item !== gender)
		setSelectedAge(array)
	}

	const handleRemoveSubRegionFromSelection = subRegion => {
		const array = selectedSubRegion?.filter(item => item !== subRegion)
		setSelectedSubRegion(array)
	}

	return (
		<div>
			<Navbar />
			<Container>
				<FlexContainer>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={24} lg={12} xl={17}>
							<div className="survey_text">Girl First Finance survey results:</div>
							<div className="survey_text_sub">{message}</div>
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={7}>
							<div className="img-wrapper">
								<img src={`${AWS_PATH}/avatar.png`} className="logo" height="250px" alt="transparent" />
							</div>
						</Col>

						<Row className="select_dropdown">
							<Col>
								<div className="input-container">
									<button
										style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										type="submit"
										onClick={() => {
											navigate('/')
										}}>
										<HomeOutlined />
									</button>
								</div>
							</Col>

							<Col className="survey_style_dropdown">
								<SurveyQuestionDropdown
									questionsList={questionsList}
									selectedQuestion={selectedQuestion}
									setSelectedQuestion={setSelectedQuestion}
								/>
							</Col>

							<Col className="survey_style_dropdown" onClick={() => setDropDownVisible(true)}>
								<SurveyQuestionDropdownSortBy
									dropDownVisible={dropDownVisible}
									genderList={genderList}
									ageList={ageList}
									continentList={continentList}
									subRegionList={subRegionList}
									selectedGenders={selectedGenders}
									setSelectedGenders={setSelectedGenders}
									selectedAge={selectedAge}
									setSelectedAge={setSelectedAge}
									selectedContinent={selectedContinent}
									setSelectedContinent={setSelectedContinent}
									selectedSubRegion={selectedSubRegion}
									setSelectedSubRegion={setSelectedSubRegion}
									fromMinority={fromMinority}
									setFromMinority={setFromMinority}
									fromRuralArea={fromRuralArea}
									setFromRuralArea={setFromRuralArea}
									haveDisability={haveDisability}
									setHaveDisability={setHaveDisability}
									setDropDownVisible={setDropDownVisible}
								/>
							</Col>

							{/* <Col>
								<div className="input-container">
									<button
										style={{ width: '100%' }}
										type="submit"
										onClick={() => {
											handleCollectSurveyData()
										}}>
										Get Survey Result{' '}
										{isQuestionLoading && (
											<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} size="small" />
										)}
									</button>
								</div>
							</Col> */}

							<Col>
								<div className="input-container">
									<button
										style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										type="submit"
										onClick={() => {
											window.location.reload()
										}}>
										↻
									</button>
								</div>
							</Col>
						</Row>
					</Row>
				</FlexContainer>

				{result ? (
					result?.length ? (
						<div style={{ paddingTop: '20px' }} ref={scrollRef}>
							<div className="table_row_style">
								<div className="survey_text_question">{displayQuestion}</div>
							</div>
							<div className="table_row_style">
								<div style={{ display: 'flex', gap: '5px' }}>
									{selectedGenders?.length > 0 &&
										selectedGenders?.map((item, key) => {
											return (
												<>
													<Tag
														className="tag_name_style"
														color="magenta"
														closable
														onClose={() => handleRemoveGenderFromSelection(item)}>
														{item}
													</Tag>

													{selectedGenders?.length - 1 === key && selectedAge?.length ? (
														<div style={{ borderLeft: '2px solid green', height: '30px', marginRight: '5px' }} />
													) : (
														''
													)}
												</>
											)
										})}

									{selectedAge?.length > 0 &&
										selectedAge?.map((item, key) => {
											return (
												<>
													<Tag
														className="tag_name_style"
														color="magenta"
														closable
														onClose={() => handleRemoveAgeFromSelection(item)}>
														{item}
													</Tag>

													{selectedAge?.length - 1 === key && selectedContinent ? (
														<div style={{ borderLeft: '2px solid green', height: '30px', marginRight: '5px' }} />
													) : (
														''
													)}
												</>
											)
										})}

									{selectedSubRegion?.length && selectedContinent ? (
										<div>
											<Tag
												className="tag_name_style"
												color="magenta"
												closable
												onClose={() => {
													setSelectedContinent(null)
													setSelectedSubRegion([])
												}}>
												{selectedContinent}
											</Tag>
										</div>
									) : (
										''
									)}

									{selectedContinent && selectedSubRegion?.length > 0
										? selectedSubRegion?.map((item, key) => {
												return (
													<>
														{0 === key && selectedContinent ? (
															<div style={{ borderLeft: '2px solid green', height: '30px', marginRight: '5px' }} />
														) : (
															''
														)}
														<Tag
															className="tag_name_style"
															color="magenta"
															closable
															onClose={() => handleRemoveSubRegionFromSelection(item)}>
															{item}
														</Tag>
													</>
												)
										  })
										: ''}
								</div>
							</div>
							<Row gutter={16} className="table_row_style_pieChart">
								<Col xs={24} sm={24} md={24} lg={12} xl={15}>
									<div className="table_contain_style" style={{ overflow: 'auto' }}>
										<Table columns={columns} dataSource={result} pagination={false} />
									</div>
								</Col>

								<Col xs={24} sm={24} md={24} lg={12} xl={9}>
									<div>
										<PieChart data={result} />
									</div>
								</Col>
							</Row>
						</div>
					) : (
						<QuestionTitle>
							<div className="survey_text_no_result">{'NO RESULTS FOUND!'}</div>
						</QuestionTitle>
					)
				) : (
					''
				)}
			</Container>
		</div>
	)
}

export default SurveyResult

const Container = styled.div`
	background: #e9f4f5;
	min-height: 100vh;
	overflow: hidden;
`
const FlexContainer = styled.div`
	display: flex;
	padding: 55px 85px;
	background: #2b5d59;
	gap: 88px;
	height: 550px;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 94%, 0% 88%);
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 94%, 0% 88%);

	@media (max-width: 768px) {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 97%, 0% 92%);
		padding: 30px;
		flex-direction: column;
		height: 900px;
	}

	@media (min-width: 768px) and (max-width: 1210px) {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 60% 97%, 0% 92%);
		padding: 30px;
		flex-direction: column;
		height: 800px;
	}

	button {
		padding: 10px 50px;
		border-radius: 8px;
		background: #fff;
		font-weight: 700;
		color: #3dad99;
		border: 0;
		cursor: pointer;
		opacity: 0.9;
		height: 50px;

		&:hover {
			background: #3dad99;
			color: #fff;
			font-weight: 700;
		}
	}
`

const QuestionTitle = styled.div`
	h6 {
		margin-left: 100px;
		font-weight: 500;
		color: #000;
		line-height: 84px;
		font-size: 25px;
	}
`

const Logo = styled.a`
	& img {
		width: 150px;
		height: auto;
	}
`
