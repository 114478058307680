import { Radio, Select, Space } from 'antd'
import '../../pages/surveyResult/styles.css'

const SurveyQuestionDropdown = ({ questionsList, setSelectedQuestion, selectedQuestion }) => {
	const onChange = e => {
		setSelectedQuestion(e.target.value)
	}

	let labelSelected = ''
	{
		questionsList &&
			questionsList.map((item, key) => {
				if (item?.value === selectedQuestion) {
					labelSelected = `${key + 1}. ${
						window.screen.width <= '768'
							? item?.label?.length > 40
								? item?.label.slice(0, 40)
								: item?.label
							: item?.label?.length > 70
							? item?.label.slice(0, 70) + '...'
							: item?.label
					}`
				}
			})
	}

	return (
		<Space wrap>
			<Select
				placeholder="Choose a question"
				className="main_question_dropdown"
				value={labelSelected}
				options={questionsList?.map((question, key) => {
					return {
						value: question?.value,
						label: (
							<Radio.Group onChange={onChange} value={selectedQuestion} style={{ width: '500px' }}>
								<Space className="space_border_style" direction="vertical">
									<Radio className="select_text_style" value={question?.value}>
										<span className="question_label">{`${key + 1}. ${question?.label}`}</span>
									</Radio>
								</Space>
							</Radio.Group>
						)
					}
				})}
			/>
		</Space>
	)
}
export default SurveyQuestionDropdown
