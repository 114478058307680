import React from 'react'
import styled from 'styled-components';
import { SectionHeader } from '../pages/Home';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { AWS_PATH } from '../constants/images';

const AnthemAward = () => {
  return (
    <Wrapper>
      <CoverRow>
        <LeftCol sm={24} md={12}>
          <SectionHeader width="500px" id="anthemAwards">
            Honored at the Anthem Awards
          </SectionHeader>
          <p className='anthem-text'>
            We're thrilled to announce our double win at the Anthem Awards! Our report, <Link to={'/research'}>"Combating the Crisis of Sex for Education and Jobs,"</Link> earned the <a target='_blank' rel="noreferrer" href='https://www.anthemawards.com/winners/list/entry/#!responsible-technology/research-projects-or-publications/combating-the-crisis-of-sex-for-education-and-jobs/1972/-1/462890'>Silver Award and Anthem Community Voice</a> in the Responsible Technology Research Projects or Publications, Awareness & Media Categories.
          </p>
          <p>Together, we drive change and amplify voices. <br />
            Thanks for your support!</p>

          <div className='button-wrapper'>
            <Link to={'/survey'}>
              <button>Speak truth to power, take our survey!</button>
            </Link>
          </div>
        </LeftCol>
        <RightCol sm={24} md={12}>
          <div className='arrow-wrapper'>
            <h3 className='colored-text'><span className='green'>Watch our</span><br /><span className='orange'>acceptance speech</span></h3>
            <img className='anthem-arrow' src={`${AWS_PATH}/anthem-award.png`} alt='anthem-award' />
          </div>
          <img className='anthem-background' src={`${AWS_PATH}/anthem-bg.png`} alt='anthem-bg' />
          <div className='video-wrapper'>

            <video width="320" height="240" controls>
              <source src={`${AWS_PATH}/Anthem-Awards.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </RightCol>
      </CoverRow>
    </Wrapper>
  )
}

export default AnthemAward

const Wrapper = styled.div`
  padding: 100px 0;
  p {
    font-size: 20px;
    text-align: left;
  }

  .anthem-text {
    & a {
      font-weight: bold;
      color: black;
      text-decoration: underline;
    }
  }
`;

const CoverRow = styled(Row)`
  align-items: flex-end;
`;

const LeftCol = styled(Col)`
  padding: 0 80px;

  #anthemAwards {
    margin-bottom: 20px;
  }

  .button-wrapper {
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 1024px) {
    padding-right: 0;
    margin-bottom: 40px;
  }

  @media (min-width: 1440px) {
    padding-left: 120px;
  }
`;

// background-image: url('/anthem-bg.png');
const RightCol = styled(Col)`
  padding-left: 100px;
  position: relative;

  .colored-text {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    rotate: 355deg;
    position: absolute;
    line-height: 1;
    padding-left: 30px;

    & .green {
      color: #3DAD99;
    }

    & .orange {
      color: #FB8A58;
    }
  }

  .arrow-wrapper {
    height: 50px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .anthem-arrow {
    width: 80px;
    position: absolute;
    left: 0;
    top: 60px;
  }

  .anthem-background {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    max-width: 400px;
    object-fit: cover;
  }

  .video-wrapper {
    background-color: #F0FAF8;
    padding: 20px;
    border-radius: 10px;
    width: 360px;
    
    margin-bottom: 30px;
    margin-left: 100px;
    margin-top: 40px;
  }
  
  video {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    margin: auto;
    position: relative;

    .anthem-background {
      width: 100%;
      right: -10%;
    }

    .video-wrapper {
      width: 100%;
      
      margin-left: 10px;
      margin-right: 20px;
      margin-top: 80px;
    }
  }

  @media (max-width: 1024px) {
    .video-wrapper {
      margin-left: 0;
    }

    .arrow-wrapper img {
      display: none;
    }
  }

  @media (min-width: 1440px) {
    padding-left: 50px;

    .anthem-background {
      width: 550px; 
      max-width: 550px;
    }

    .video-wrapper {
      margin-left: 20%;
    }
    
    .arrow-wrapper {
      margin-left: 8%;
    }
  }
`;
